import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import StoreListingHero from "../components/utils/StoreListingHero";

const AboutUsPage = () => {
    return (
      <div>
        <Header />
        <StoreListingHero
          title="Our Values"
          imageUrl="https://images.unsplash.com/photo-1524601500432-1e1a4c71d692?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
        <section className="w-screen bg-white py-10 text-gray-800">
          <div className="container mx-auto w-full max-w-screen-xl">
            <div className="w-full">
              <h2 className="text-center text-3xl font-extrabold">
                Our Values
              </h2>
              <p className="mx-auto mb-4 max-w-xl py-2 text-center text-gray-600 sm:text-lg">
                Explore our Guiding Principles, and the Values that drive us to
                create a better future for our customers, our people, our
                communities, and our planet.
              </p>
            </div>
            <div className="flex flex-col lg:flex-row">
              <div className="w-full p-4 text-left lg:w-1/3">
                <hr className="mb-4 h-1.5 w-1/4 bg-[#f8aca3]" />
                <h3 className="font-sans text-4xl font-light leading-10">
                  INNOVATE.
                </h3>
                <p className="my-5 text-gray-600">
                  {" "}
                  At GlowGenie, innovation is our guiding star. We're constantly
                  searching for fresh ways to enhance your beauty experience.
                  We're captivated by creative solutions that redefine the
                  skincare and beauty industry. Our commitment to innovation
                  means that we're dedicated to exploring new technologies,
                  methods, and products that will illuminate your path to
                  radiant skin and self-confidence. We are enthusiastic about
                  staying at the forefront of the beauty world, delivering you
                  the most advanced and personalized skincare solutions.
                </p>
              </div>
              <div className="w-full p-4 text-left lg:w-1/3">
                <hr className="mb-4 h-1.5 w-1/4 bg-[#f8aca3]" />
                <h3 className="font-sans text-4xl font-light leading-10">
                  GROW.
                </h3>
                <p className="my-5 text-gray-600">
                  Growth isn't just about beauty; it's about your journey. At
                  GlowGenie, we're not only here to support your skincare goals
                  but your personal growth as well. We aim to provide you with
                  the tools to nurture your inner and outer beauty. We believe
                  in fostering an environment where your aspirations can
                  flourish, offering the finest products and personalized
                  recommendations to help you shine. We grow when you grow, and
                  we're excited to be a part of your transformative beauty
                  journey.
                </p>
              </div>
              <div className="w-full p-4 text-left lg:w-1/3">
                <hr className="mb-4 h-1.5 w-1/4 bg-[#f8aca3]" />
                <h3 className="font-sans text-4xl font-light leading-10">
                  SERVE.
                </h3>
                <p className="my-5 text-gray-600">
                  At GlowGenie, service isn't just a duty; it's our calling. We
                  are dedicated to serving you with the utmost care, respect,
                  and integrity. Your beauty and confidence are our top
                  priorities, and we're committed to understanding your unique
                  skincare needs. Our service goes beyond the products and
                  recommendations; it's about providing you with access to top
                  dermatologists and a community of beauty enthusiasts who share
                  your passion. Your trust is our most cherished asset, and we
                  aim to honor it by making you feel beautiful and confident
                  every day.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
};

export default AboutUsPage;