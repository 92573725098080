import React from 'react'
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Events/Events";

import Footer from "../components/Layout/Footer";
//import Announcements from '../components/Layout/Announcements';

import OurStores from '../components/Route/Stores/Stores';
import CueTheNew from '../components/Route/CueTheNew/CueTheNew';

import Cta1 from '../components/Route/CTAS/Cta1';
import Cta2 from '../components/Route/CTAS/Cta2';


import SellerOfTheMonth from '../components/Route/Stores/SellerOfTheMonth';

import BeforeAfterSlider from './../components/Route/Hero/BeforeAndAfter';

//import ProductCard2 from '../components/Route/ProductCard/ProductCard';

const HomePage = () => {
  return (
    <div>
      {/* <Announcements /> */}
      {/* <Header activeHeading={1} /> */}
      <Header />
      <Hero />

      <BeforeAfterSlider className="z-0" />

      <div className="grid grid-cols-2 gap-8 mt-20 max-w-7xl mx-auto px-5">
        <Cta1 />
        <Cta2 />
      </div>
      <div>
        <Categories />{" "}
      </div>

      <CueTheNew />
      <BestDeals />
      {/* <OurStores /> */}

      <FeaturedProduct />
      <SellerOfTheMonth />
      <Events />

      {/* <ProductCard2 /> */}

      <Footer />
    </div>
  );
}

export default HomePage