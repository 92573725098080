import axios from "axios";
import { server } from "../../server";

// create blog
export const createBlog =
  (title, content, authorId, authorModel, avatar) => async (dispatch) => {
    try {
      dispatch({
        type: "blogCreateRequest",
      });

      const { data } = await axios.post(`${server}/blog/create-blog`, {
        title,
        content,
        authorId,
        authorModel,
        avatar,
      });
      dispatch({
        type: "blogCreateSuccess",
        payload: data.savedBlog,
      });
    } catch (error) {
      //console.log("🚀 ~ file: blog.js:24 ~ error:", error)
      dispatch({
        type: "blogCreateFail",
        payload: error?.response?.data?.message,
      });
    }
  };

export const updateBlog =
    (title, id, content, authorId, authorModel, avatar) => async (dispatch) => {
      try {
        dispatch({
          type: "blogCreateRequest",
        });

        const { data } = await axios.put(`${server}/blog/update-blog/${id}`, {
          title,
          content,
          authorId,
          authorModel,
          avatar,
        });
        console.log("🚀 ~ file: blog.js:41 ~ data:", data);
        dispatch({
          type: "blogUpdateSuccess",
          payload: data.savedBlog,
        });
      } catch (error) {
        //console.log("🚀 ~ file: blog.js:24 ~ error:", error);
        dispatch({
          type: "blogUpdateFail",
          payload: error?.response?.data?.message,
        });
      }
    };

export const getBlog = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getBlogRequest",
    });

    const { data } = await axios.get(`${server}/blog/get-blog/${id}`);

    dispatch({
      type: "getBlogSuccess",
      payload: data.blog,
    });
  } catch (error) {
    dispatch({
      type: "getBlogFailed",
      payload: error?.response?.data?.message,
    });
  }
};

// delete blog of a shop
export const deleteBlog = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteBlogRequest",
    });

    const { data } = await axios.delete(
      `${server}/blog/delete-shop-blog/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteBlogSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteBlogFailed",
      payload: error?.response?.data?.message,
    });
  }
};

// get all blogs
export const getAllBlogs = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllBlogsRequest",
    });

    const { data } = await axios.get(`${server}/blog/get-all-blogs`);
         // console.log("🚀 ~ file: blog.js:87 ~ getAllBlogs ~ data:", data);
    dispatch({
      type: "getAllBlogsSuccess",
      payload: data,
      
    });
  } catch (error) {
    dispatch({
      type: "getAllBlogsFailed",
      payload: error?.response?.data?.message,
    });
  }
};
