import React, { useState, } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { Typography, Modal, TextField, Box } from "@mui/material";


import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const DermatologistCard = ({ dermatologist }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClose = () => setIsModalOpen(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const { user, isAuthenticated } = useSelector((state) => state.user);

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = dermatologist._id + user._id;
      const userId = user._id;
      const dermatologistId = dermatologist._id;
      await axios
        .post(`${server}/conversation/create-new-dermatologist-conversation`, {
          groupTitle,
          userId,
          dermatologistId,
        })
        .then((res) => {
         // console.log("🚀 ~ file: DermatologistCard.jsx:46 ~ .then ~ res:", res.data)
          navigate(`/inbox?${res?.data?.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please Login as a userto Create a Conversation");
    }
  };
  return (
    <div>
      {" "}
      <div
        //onClick={() => setIsModalOpen(true)}
        className="p-4 bg-white shadow-lg rounded-2xl  dark:bg-gray-800 h-720"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex flex-row items-start gap-4">
          <img
            src={dermatologist?.avatar?.url}
            className="rounded-lg w-42 object-cover"
          />
        </div>
        <div>
          <div>
            <p className="text-xl font-medium text-gray-800 dark:text-white">
              {dermatologist?.name}
            </p>
          </div>
          <h3 className="font-lg text-semibold text-center leading-6 text-gray-900 mt-5">
            {dermatologist?.description === ""
              ? "No Description Available"
              : dermatologist?.description}
          </h3>
          <p className="text-center text-sm leading-6 text-gray-500 hover:text-gray-600"></p>
          <ul className="mt-3 divide-y rounded bg-gray-100 py-2 px-3 text-gray-600 shadow-sm hover:text-gray-700 hover:shadow">
            <li className="flex items-center py-3 text-sm">
              <span>Status</span>
              <span className="ml-auto">
                <span className="rounded-full bg-green-200 py-1 px-2 text-xs font-medium text-green-700">
                  Active
                </span>
              </span>
            </li>
            <li className="flex items-center py-3 text-sm">
              <span>Joined On</span>
              <span className="ml-auto">
                {" "}
                {dermatologist?.createdAt.substring(0, 10)}{" "}
              </span>
            </li>
            <li className="flex items-center py-3 text-sm">
              <span>Address</span>
              <span className="ml-auto"> {dermatologist?.address}</span>
            </li>
          </ul>{" "}
        </div>
        <button
        onClick={handleMessageSubmit}
          className={`w-full flex mt-5 items-center justify-center rounded-md border 
           border-red-500 text-[#f84d17] px-5 py-2.5 text-center text-lg font-medium hover:bg-[#f84d17] hover:text-white
              ${isHovered ? "bg-[#f84d17] text-white " : ""}`}
        >
          Send Message
        </button>{" "}
        {/* <div className="flex items-center justify-between gap-4 mt-6">
          <button
            type="button"
            className="w-1/2 px-4 py-2 text-base bg-white border rounded-lg text-grey-500 hover:bg-gray-200 "
          >
            Chat
          </button>
          <button
            type="button"
            className="w-1/2 px-4 py-2 text-base text-white bg-indigo-500 border rounded-lg hover:bg-indigo-700 "
          >
            Add friend
          </button>
        </div> */}
      </div>
      {/* <Modal open={isModalOpen} onClose={handleClose}>
        <Box sx={style}>
          <div className="flex items-start mb-4">
            <div className="ml-auto" onClick={handleClose}>
              <FaTimes className=" text-3xl font-thin text-red-500" />
            </div>
          </div>

          <div className="modal-body">
            <div className="left-section">
              <img src={dermatologist?.avatar?.url} alt={dermatologist?.name} />
            </div>
            <div className="right-section mt-5">
              <Typography variant="h6">{dermatologist?.name}</Typography>
              <Typography>{dermatologist?.description}</Typography>
              <TextField
                label="Your Message"
                variant="outlined"
                fullWidth
                multiline
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                margin="normal"
              />
              <button
                //variant="contained"
                className="w-full mt-4 bg-theme-button-bg px-3 py-2 text-white rounded-md "
                onClick={handleMessageSubmit}
              >
                Send Message
              </button>
            </div>
          </div>
        </Box>
      </Modal>{" "} */}
    </div>
  );
};
export default DermatologistCard;
