import React, { useState } from "react"; 
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import axios from "axios";
import { toast } from "react-toastify"; 
import { server } from "../server";

const ContactUsPage = () => {
  // State to manage form inputs
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  // Function to handle form submission
   const handleSubmit = async (e) => {
     e.preventDefault();
     try {
       const response = await axios.post(`${server}/user/contact-us`, { email, subject, message });
       if (response.data.success) {
         toast.success(response.data.message);
          setEmail("");
          setSubject("");
          setMessage("");
       } else {
         toast.error(
           "There was an error sending your message. Please try again."
         );
       }
     } catch (error) {
       toast.error(
         "There was an error sending your message. Please try again."
       );
     }
   };


  return (
    <div>
      <Header />
      <div className="font-sans text-base text-gray-900 sm:px-10">
        <div className="text-base text-gray-900">
          <div className="mx-auto w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">
            <div className="mx-2 pt-12 text-center md:mx-auto md:w-2/3 md:pb-12">
              <h1 className="mb-4 text-3xl font-black sm:text-5xl xl:text-6xl font-sans">
                Contact us
              </h1>
              <div className="text-lg sm:text-xl xl:text-xl">
                <div className="font-sans font-light">
                  <p className="mb-4">
                    Get in touch with us - we're here to help!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto mb-20 bg-white flex w-full max-w-screen-xl flex-col overflow-hidden rounded-xl text-gray-900 md:flex-row md:border md:shadow-lg">
          <form
            className="mx-auto w-full max-w-xl border-gray-200 px-10 py-8 md:px-8"
            onSubmit={handleSubmit}
          >
            <div className="mb-4">
              <label
                className="text mb-2 block font-medium font-sans"
                htmlFor="email"
              >
                Your E-mail:
              </label>
              <input
                className="w-full rounded border border-gray-300 px-3 py-2 outline-none"
                id="email"
                type="email"
                required=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="text font-sans mb-2 block font-medium"
                htmlFor="subject"
              >
                Subject:
              </label>
              <input
                className="w-full rounded border border-gray-300 px-3 py-2 outline-none"
                id="subject"
                type="text"
                required=""
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="text mb-2 block font-sans font-medium"
                htmlFor="message"
              >
                Message:
              </label>
              <textarea
                className="h-52 w-full rounded border border-gray-300 px-3 py-2 outline-none"
                id="message"
                required=""
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="flex items-center">
              <div className="flex-1"></div>
              <button
                className={`w-full font-sans flex mt-5 items-center justify-center rounded-md border 
                          border-red-500 text-red-500 px-5 py-2.5 text-center text-lg font-medium hover:bg-red-500 hover:text-white`}
                type="submit"
              >
                Send Message
              </button>
            </div>
          </form>
          <div className="mt-10 font-sans font-light px-10 py-8 text-black md:mt-0 md:ml-auto bg-blue-gray-300">
            <div className="">
              <p className="mb-4 font-medium border-b  pb-2">OFFICE HOURS</p>
              <p className="mb-4 ">Monday – Thursday: 08:00 – 16:00</p>
              <p className="mb-4">Friday: 08:00 - 15:00</p>
              <hr className="my-2 h-0 border-t border-r-0 border-b-0 border-l-0 border-gray-300 border-solid border-2" />
              <p className="mb-4">Weekend: Closed</p>
              <hr className="my-2 h-0 border-t border-r-0 border-b-0 border-l-0 border-gray-300 border-solid border-2" />
              <p className="mb-4">
                Email:
                <a href="" className="font-semibold underline">
                  support@glowgenie.io
                </a>
              </p>
              <hr className="my-2 h-0 border-t border-r-0 border-b-0 border-l-0 border-gray-300 border-solid border-2" />
              <p className="mb-4">
                Phone:
                <a href="" className="font-semibold underline">
                  +254 711 222 333
                </a>
              </p>
              <hr className="my-2 h-0 border-t border-r-0 border-b-0 border-l-0 border-gray-300 border-solid border-2" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUsPage;
