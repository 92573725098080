import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import { AiOutlineArrowRight } from "react-icons/ai";

import Announcements from "../../components/Layout/Announcements";
import StoreListingHero from "../../components/utils/StoreListingHero";
import DermatologistList from "../../components/Dermatologist/DermatologistList";
import DermatologistTestimonialSlider from "../../components/Dermatologist/DermatologistTestimonials";
//import DermatologistHero from "../../components/Dermatologist/DermatologistHero";

const DermatologistListPage = () => {
  const [searchParams] = useSearchParams();
  //   const categoryData = searchParams.get("category");
  // const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  return (
    <>
      <div>
        {/* <Announcements /> */}
        <Header activeHeading={5} />
        <StoreListingHero
          title={"Our Dermatologists"}
          imageUrl="https://img.freepik.com/premium-photo/portrait-group-diverse-male-female-nurses-doctors-medical-team_396607-16501.jpg?w=1380"
        />
        {/* <DermatologistHero/> */}
        <br />
        <br />
        <div className={`w-9/12 mx-auto`}>
          <div className="flex flex-col items-center text-center">
            <div className="text-4xl italic font-semibold my-5">
              Unleash Your Skin's Potential with Expert Dermatological Care
            </div>
            <div className="text-2xl font-light">
              Experience the difference with our board-certified dermatologists,
              who bring{" "}
              <span className="font-semibold">Decades of Expertise </span> and a{" "}
              <span className="font-semibold">Patient-First Approach </span> to
              every consultation. Discover{" "}
              <span className="font-semibold">
                Personalized Skincare Solutions{" "}
              </span>
              that not only treat but also prevent skin issues, enhancing your
              natural beauty and health. Start your journey towards radiant skin
              with us today!
            </div>
          </div>

          <div className="mt-10">
            <DermatologistList />
          </div>
          <DermatologistTestimonialSlider/>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default DermatologistListPage;
