import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const dermatologistReducer = createReducer(initialState, {
  LoadDermatologistRequest: (state) => {
    state.isLoading = true;
  },
  LoadDermatologistSuccess: (state, action) => {
    state.isDermatologist = true;
    state.isLoading = false;
    state.dermatologist = action.payload;
  },
  LoadDermatologistFail: (state, action) => {
    state.isLoading = false; 
    state.error = action.payload;
    state.isDermatologist = false;
  },

  // update dermatologist information
  updateDermatologistInfoRequest: (state) => {
    state.isLoading = true;
  },
  updateDermatologistInfoSuccess: (state, action) => {
    state.isLoading = false;
    state.dermatologist = action.payload;
  },
  updateDermatologistInfoFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // update dermatologist address
  updateDermatologistAddressRequest: (state) => {
    state.isLoading = true;
  },
  updateDermatologistAddressSuccess: (state, action) => {
    state.isLoading = false;
    state.successMessage = action.payload.successMessage;
    state.dermatologist = action.payload.dermatologist;
  },
  updateDermatologistAddressFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete dermatologist address
  deleteDermatologistAddressRequest: (state) => {
    state.isLoading = true;
  },
  deleteDermatologistAddressSuccess: (state, action) => {
    state.isLoading = false;
    state.successMessage = action.payload.successMessage;
    state.dermatologist = action.payload.dermatologist;
  },
  deleteDermatologistAddressFailed: (state, action) => {
    state.isloading = false;
    state.error = action.payload;
  },

  // get all dermatologists --- admin
  getAllDermatologistsRequest: (state) => {
    state.isLoading = true;
  },
  getAllDermatologistsSuccess: (state, action) => {
    state.isLoading = false;
    state.dermatologists = action.payload;
  },
  getAllDermatologistFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
  clearMessages: (state) => {
    state.successMessage = null;
  },
});
