import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllSellers } from "../redux/actions/sellers";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import { StoreCard } from "../components/Route/Stores/Stores";
import styles from "../styles/styles";

//import Announcements from "../components/Layout/Announcements";
import StoreListingHero from "../components/utils/StoreListingHero";
import StoreCollection from "../components/Route/Stores/StoreCollection";

const StoresPage = () => {
  const [data, setData] = useState([]);
  const { sellers, isLoading } = useSelector((state) => state.seller);

  useEffect(() => {
    const allSellersData = sellers ? [...sellers] : [];
    setData(allSellersData);
  }, [sellers]);

  return (
    <>
      <Header />
      {isLoading & <Loader />}
      {!isLoading && (
        <div>
          <StoreListingHero
            title="Store Listings"
            imageUrl="https://images.unsplash.com/photo-1603912699214-92627f304eb6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=725&q=80"
          />
          <br />
          <br />
          <div className={`w-11/12 mx-auto`}>
            {data && data.length === 0 && (
              <h1 className="text-center my-5 font-semibold text-lg">
                No Stores Currently!
              </h1>
            )}
            <div>
              {/* {data &&
                data.length > 0 &&
                data.map((i, index) => <StoreCard store={i} key={index} />)} */}
              <div className="text-center mb-5 text-4xl font-light uppercase mt-10">
                <h2>OUR VENDORS </h2>
              </div>
              {data && data.length > 0 && <StoreCollection stores={data} />}
            </div>
          </div>

          {/* <Footer /> */}
        </div>
      )}
      <Footer />
    </>
  );
};

export default StoresPage;