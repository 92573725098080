import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom"; 
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import StoreListingHero from "../../components/utils/StoreListingHero";
import { FaPlus } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { getAllBlogs } from "../../redux/actions/blog";
import BlogPlaceholder from "../../Assests/images/BlogPlaceholder.avif";
import Loader from "../../components/Layout/Loader";

import CreateBlogForm from "../../components/blog/CreateBlogForm";


export default function BlogPage() {
  const [showModal, setShowModal] = useState(false);
    const { user } = useSelector((state) => state?.user);
    const { isDermatologist, dermatologist } = useSelector(
      (state) => state?.dermatologist
    );

    const dispatch = useDispatch();
    const { isSeller, seller } = useSelector((state) => state?.seller);
    const {isLoading, blogs} = useSelector((state) => state?.blog);
    //console.log("🚀 ~ file: BlogPage.jsx:84 ~ BlogPage ~ blogs:", blogs)

  useEffect(() => {
     dispatch(getAllBlogs());
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Header />
      <StoreListingHero
        title="Blog Section"
        imageUrl="https://images.unsplash.com/photo-1542435503-956c469947f6?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto w-9/12 px-6 lg:px-8">
            <div className="mx-auto  lg:mx-0 flex justify-between   items-center">
              <div>
                <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  From the Blog
                </h2>
                <p className="mt-2 text-2xl leading-8 text-gray-600">
                  Discover the latest insights, tips, and trends in our blog.
                </p>
              </div>

              {user?.role === "Admin" ? (
                <div className="">
                  <button
                    onClick={toggleModal}
                    className={`w-50 flex mt-5 items-center justify-center rounded-md border 
                                         border-red-500 text-[#f84d17] px-5 py-2.5 text-center text-lg font-medium hover:bg-[#f84d17] hover:text-white`}
                  >
                    <FaPlus className="mr-2" /> Add New Blog Post
                  </button>
                </div>
              ) : null}
            </div>

            <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {!isLoading &&
                blogs &&
                blogs.map((post) => (
                  <Link to={`/blog/${post._id}`} key={post.id}>
                    {" "}
                    {/* Wrap the article with the Link component */}
                    <article className="flex max-w-xl flex-col items-start justify-between">
                      <div className="relative">
                        <img
                          src={post.avatar ? post.avatar : BlogPlaceholder}
                          alt=""
                          className="w-full h-64 object-cover rounded-lg"
                        />
                      </div>
                      <div className="flex items-center gap-x-4 text-sm">
                        <time
                          dateTime={post.datetime}
                          className="text-gray-700"
                        >
                          {post?.createdAt.substring(0, 10)}
                        </time>
                        {/* <a
                        href={post?.category?.href}
                        className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                      >
                        {post?.category?.title}
                      </a> */}
                      </div>
                      <div className="group relative">
                        <h3 className="mt-3 text-2xl font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                          <Link to={`/blog/${post?._id}`}>
                            <span className="absolute inset-0" />
                            {post?.title}
                          </Link>
                        </h3>
                        <p className="mt-5 line-clamp-3 text-lg leading-6 text-gray-600">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: post?.content,
                            }}
                          />
                        </p>
                      </div>
                      <div className="relative mt-8 flex items-center gap-x-4">
                        <img
                          src={post?.author?.avatar?.url}
                          alt=""
                          className="h-12 w-12 rounded-full bg-gray-50"
                        />
                        <div className="text-sm leading-6">
                          <p className="font-semibold text-gray-900">
                            <a href={""}>
                              <span className="absolute inset-0" />
                              {post?.author?.name}
                            </a>
                          </p>
                          <p className="text-gray-600">{post?.author?.role}</p>
                        </div>
                      </div>
                    </article>
                  </Link>
                ))}
            </div>
          </div>
          {showModal && (
            <div
              id="defaultModal"
              className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-gray-500 bg-opacity-50"
              onClick={toggleModal}
            >
              <div
                className="relative p-4 w-full max-w-2xl bg-white rounded-lg shadow sm:p-5"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5">
                  <h3 className="text-2xl font-semibold text-gray-900">
                    Add Blog Post
                  </h3>
                  <button
                    type="button"
                    onClick={toggleModal}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-2xl p-1.5 ml-auto inline-flex items-center"
                  >
                    <IoMdClose />
                  </button>
                </div>
                <CreateBlogForm />
              </div>
            </div>
          )}
        </div>
      )}
      <Footer />
    </>
  );
}
