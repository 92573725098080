import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Select, Option } from "@material-tailwind/react";
import StoreSpecialtyFilter from "./StoreSpecialtyFilter";

const StoreCollection = ({ stores }) => {
  const [sortedStores, setSortedStores] = useState([]);
  const [specialties, setSpecialties] = useState([]); // [
  const { allProducts, isLoading } = useSelector((state) => state?.products);

  useEffect(() => {
    if (specialties.length === 0) {
      // If "All" is selected, show all stores
      setSortedStores(stores);
    } else {
      // Otherwise, filter the stores based on the selected specialties
      setSortedStores(
        stores?.filter((store) =>
          specialties.some((specialty) => store?.specialty.includes(specialty))
        )
      );
    }
  }, [specialties, stores]);

  const handleSort = (order) => {
    let sortedData = [...stores];
    if (order === "ASC") {
      sortedData.sort((a, b) => a.name.localeCompare(b.name));
    } else if (order === "DESC") {
      sortedData.sort((a, b) => b.name.localeCompare(a.name));
    }
    setSortedStores(sortedData);
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-end w-full">
        <div className="my-5 w-72 mr-10">
          <Select color="red" label="Sort By" size="lg" onChange={handleSort}>
            <Option value="ASC">Sort By Name, ASC</Option>
            <Option value="DESC">Sort By Name, DESC</Option>
          </Select>{" "}
        </div>
        <div className="flex">
          <StoreSpecialtyFilter setSpecialties={setSpecialties} />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-[20px] lg:grid-cols-2 lg:gap-[25px] xl:grid-cols-2 xl:gap-[30px] mb-12">
        {sortedStores.map((store) => {
          const storeProducts = allProducts
            ? allProducts
                .filter((product) => product?.shopId === store?._id)
                .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
                .slice(0, 4)
            : [];
          return (
            <div key={store._id} className="bg-white p-8 border rounded">
              <div className="flex flex-row items-start gap-4">
                <img
                  src={store?.avatar?.url}
                  className="rounded-lg w-[200px] object-cover"
                />
              </div>

              <div className="flex items-center justify-between">
                <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
                  {store?.name}
                </h2>
                <Link to={`/shop/preview/${store?._id}`}>
                  <button
                    className={`w-100 flex mt-5 items-center justify-center rounded-md border 
           border-[#f84d17] text-[#f84d17] px-5 py-2.5 text-center text-lg font-medium hover:bg-[#f84d17] hover:text-white`}
                  >
                    View Store
                  </button>{" "}
                </Link>
              </div>
              <p className="max-w-md mt-4 text-gray-500">
                {store?.description === undefined
                  ? "No Description Currently Available"
                  : store?.description}
              </p>

              <ul className="mt-3 divide-y rounded bg-gray-100 py-2 px-3 text-gray-600 shadow-sm hover:text-gray-700 hover:shadow">
                <li className="flex items-center py-3 text-sm">
                  <span>Status</span>
                  <span className="ml-auto">
                    {store?.status === "Active" && (
                      <span className="rounded-full bg-green-200 py-1 px-2 text-xs font-medium text-green-700">
                        Active
                      </span>
                    )}
                  </span>

                  <span className="ml-auto">
                    {store?.status === "Inactive" && (
                      <span className="rounded-full bg-red-200 py-1 px-2 text-xs font-medium text-red-700">
                        Inactive
                      </span>
                    )}
                  </span>
                </li>
                <li className="flex items-center py-3 text-sm">
                  <span>Specialty</span>
                  <span className="ml-auto">
                    {" "}
                    <span className="ml-auto">
                      {" "}
                      {store?.specialty === undefined ||
                      store?.specialty.length === 0
                        ? "Not Specified"
                        : store?.specialty.join(", ")}
                    </span>
                  </span>
                </li>
                <li className="flex items-center py-3 text-sm">
                  <span>Joined On</span>
                  <span className="ml-auto">
                    {" "}
                    {store?.createdAt.substring(0, 10)}{" "}
                  </span>
                </li>
                <li className="flex items-center py-3 text-sm">
                  <span>Address</span>
                  <span className="ml-auto"> {store?.address}</span>
                </li>
              </ul>

              <h2 className="text-lg font-semibold text-gray-800 sm:text-2xl mt-5">
                Latest Products
              </h2>
              {storeProducts.length > 0 ? (
                <ul className="grid gap-4 mt-8 sm:grid-cols-2 lg:grid-cols-4">
                  {storeProducts.map((product) => (
                    <li key={product?._id}>
                      <Link
                        to={`/product/${product?._id}`}
                        className="block overflow-hidden group"
                      >
                        <img
                          src={product?.images && product?.images[0]?.url}
                          alt=""
                          className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                        />

                        <div className="relative pt-3 bg-white">
                          <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4">
                            {product?.name}
                          </h3>

                          <p className="mt-2">
                            <span className="sr-only"> Regular Price </span>

                            <span className="tracking-wider text-gray-900">
                              {" "}
                              Ksh {product?.discountPrice}
                            </span>
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="flex items-center justify-center">
                  No Products to showcase for this Store
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StoreCollection;
