import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";

const ProductCard = ({ data, isEvent }  ) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    //console.log("single product data", data);
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

const calculateDiscountPercentage = (originalPrice, discountPrice) => {
  if(!originalPrice || !discountPrice) return `0% OFF`;
    const discount = originalPrice - discountPrice;
    const discountPercentage = Math.round((discount / originalPrice) * 100);
    return `${discountPercentage}% OFF`;
};


  return (
    <div
      className={`relative m-10 flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md cursor-pointer transition-transform duration-300 ${
        hovered ? "hovered" : ""
      }`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Link
        to={`${
          isEvent === true
            ? `/product/${data?._id}?isEvent=true`
            : `/product/${data?._id}`
        }`}
      >
        <img
          className="h-[300px]  object-cover transition-transform transform scale-100 hover:scale-110"
          src={`${data?.images && data?.images[0]?.url}`}
          alt="product image"
        />
        <span className="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">
          {calculateDiscountPercentage(
            data?.originalPrice,
            data?.discountPrice
          )}
        </span>
      </Link>
      <div className="mt-4 px-5 pb-5">
        <Link
          to={`${
            isEvent === true
              ? `/product/${data?._id}?isEvent=true`
              : `/product/${data?._id}`
          }`}
        >
          <h5 className="text-xl tracking-tight text-slate-900">
            {data?.name?.length > 25
              ? data?.name.slice(0, 25) + "..."
              : data?.name}
          </h5>

          <p className="mt-2 text-sm text-slate-900">
            {data?.description?.length > 25
              ? data?.description?.slice(0, 25) + "..."
              : data?.description}
          </p>
        </Link>
        {/* <div className="mt-2 mb-5 flex items-center justify-between">
          <p>
            <span className="text-lg mr-1 font-bold text-slate-900">
              {data.originalPrice === 0
                ? "KSh " + data.originalPrice
                : "KSh " + data.discountPrice}
            </span>
            <span className="text-sm text-slate-900 line-through">
              {data.originalPrice ? "KSh" + data.originalPrice : null}
            </span>
          </p>
        </div> */}
        <div className="my-2 flex items-center justify-between text-[#333]">
          <div className="flex">
            <h5 className={`${styles.productDiscountPrice}`}>
              {data.originalPrice === 0
                ? "KSh " + data.originalPrice
                : "KSh " + data.discountPrice}
            </h5>
            <h4 className={`${styles.price}`}>
              {data.originalPrice ? "KSh" + data.originalPrice : null}
            </h4>
          </div>
          <span className="font-[400] text-[17px] text-[#333]">
            {data?.sold_out} Sold
          </span>
        </div>
        <div className="flex">
          <Ratings rating={data?.ratings} />
        </div>
        <Link
          to={
            isEvent === true
              ? `/product/${data._id}?isEvent=true`
              : `/product/${data._id}`
          }
          className="w-full mx-2"
        >
          <button
            className={`w-full flex items-center justify-center rounded-md border 
           border-[#f84d17] text-[#f84d17] px-5 py-2.5 text-center text-sm font-medium hover:bg-[#f84d17] hover:text-white
              ${hovered ? "bg-[#f84d17] text-white " : ""}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            SHOP NOW
          </button>{" "}
        </Link>{" "}
        {/* side options */}
        <div>
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#f84d17"}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer absolute right-2 top-14"
            onClick={() => setOpen(!open)}
            color="#f84d17"
            title="Quick view"
          />
          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer absolute right-2 top-24"
            onClick={() => addToCartHandler(data._id)}
            color="#f84d17"
            title="Add to cart"
          />
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
