import React from "react";
import Header  from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";

const PrivacyPolicyPage = () => {
    return (
      <div>
        <Header />

        <div className="">
          <div className="bg-white">
            <section className="mx-auto grid max-w-screen-xl gap-y-4 gap-x-20 px-4 py-10 sm:px-10 lg:grid-cols-10">
              <h2 className="w-full text-3xl font-bold text-gray-800 sm:text-4xl lg:col-span-3">
                Terms of service
              </h2>

              <div className="mr-20 text-gray-600 lg:col-span-5">
                <p className="mb-1 font-medium">Summary</p>
                <p className="">
                  By accessing and using our website or services, you agree to
                  abide by these Terms of Service. You must be at least 18 years
                  old to use our services. You agree not to engage in any
                  illegal or unauthorized activities, including but not limited
                  to unauthorized access to our systems, data, or content. We
                  reserve the right to suspend or terminate your account if you
                  violate these terms. Our services are provided "as is" and
                  without warranties. We are not liable for any damages or
                  losses resulting from your use of our services. Please review
                  our Privacy Policy for information on data collection and
                  usage. These terms are subject to change, and it is your
                  responsibility to stay updated.
                </p>
              </div>
              <div className="lg:col-span-2">
                <p className="font-medium">Download</p>
                <a href="" className="font-medium text-blue-600">
                  .pdf
                </a>
              </div>
            </section>
          </div>
          <div className="bg-gray-100">
            <section className="mx-auto grid max-w-screen-xl gap-y-4 gap-x-20 border-b border-gray-300 px-4 py-10 sm:px-10 lg:grid-cols-10">
              <h2 className="w-full text-3xl font-bold text-gray-800 sm:text-4xl lg:col-span-3">
                Privacy Policy
              </h2>

              <div className="mr-20 text-gray-600 lg:col-span-5">
                <p className="mb-1 font-medium">Summary</p>
                <p className="">
                  We take your privacy seriously and are committed to protecting
                  your personal information. When you use our services, we may
                  collect certain data, such as your name, contact information,
                  and browsing behavior, to provide you with a better
                  experience. We do not share your information with third
                  parties unless required by law or with your explicit consent.
                  We use reasonable security measures to protect your data but
                  cannot guarantee absolute security. By using our services, you
                  agree to this Privacy Policy. For more details on data
                  collection, usage, and your rights, please read our full
                  Privacy Policy.
                </p>
              </div>
              <div className="lg:col-span-2">
                <p className="font-medium">Download</p>
                <a href="" className="font-medium text-blue-600">
                  .pdf
                </a>
              </div>
            </section>
          </div>
          <div className="bg-white">
            <section className="mx-auto grid max-w-screen-xl gap-y-4 gap-x-20 px-4 py-10 sm:px-10 lg:grid-cols-10">
              <h2 className="w-full text-3xl font-bold text-gray-800 sm:text-4xl lg:col-span-3">
                User Conduct
              </h2>

              <div className="mr-20 text-gray-600 lg:col-span-5">
                <p className="mb-1 font-medium">Summary</p>
                <p className="">
                  By using our platform, you agree to conduct yourself in a
                  respectful and lawful manner. You must not engage in any
                  activity that violates applicable laws or infringes on the
                  rights of others. This includes refraining from harassing,
                  defaming, or harming other users, as well as avoiding any
                  unauthorized access or misuse of our system. We do not
                  tolerate hate speech, discrimination, or any form of harmful
                  behavior. Violation of this agreement may result in the
                  termination of your account. Your use of our platform is
                  subject to these conduct guidelines, and we encourage a
                  positive and inclusive community.
                </p>
              </div>
              <div className="lg:col-span-2">
                <p className="font-medium">Download</p>
                <a href="" className="font-medium text-blue-600">
                  .pdf
                </a>
              </div>
            </section>
          </div>
        </div>

        <Footer />
      </div>
    );
};

export default PrivacyPolicyPage