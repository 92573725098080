import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DermatologistLogin from '../../components/Dermatologist/DermatologistLogin';

const DermatologistLoginPage = () => {
  const navigate = useNavigate();
const {dermatologistLoading, isDermatologist} = useSelector((state) => state.dermatologist);

  useEffect(() => {
    if (isDermatologist === true) {
      navigate(`/dermatologist-dashboard`);
    }
  }, [isDermatologist])
  return (
    <div>
        <DermatologistLogin/>
    </div>
  )
}

export default DermatologistLoginPage