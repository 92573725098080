export const quizJson = {
  title: "Answer a Few Questions to Receive Personalized Product Recommendations",
  // "completedHtml": "<h3>Thank you for your feedback</h3>",
  // "onComplete": "onSurveyComplete",

  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "rating",
          name: "question1",
          title:
            "My skin often feels Oily, especially in the T-Zone (Forehead, Nose, Chin).",
          isRequired: true,
          autoGenerate: false,
          rateValues: [
            {
              value: 1,
             text: "1",
            },
            {
              value: 2,
             text: "2",
            },
            {
              value: 3,
             text: "3",
            },
            {
              value: 4,
             text: "4",
            },
            {
              value: 5,
             text: "5",
            },
          ],
          rateMin: 1,
          minRateDescription: "Strongly Agree",
          maxRateDescription: "Strongly  Disagree",
        },
        {
          type: "rating",
          name: "question2",
          title: "I frequently experience breakouts or blackheads.",
          isRequired: true,
          rateValues: [
            {
              value: 1,
              text: "1",
            },
            {
              value: 2,
              text: "2",
            },
            {
              value: 3,
             text: "3",
            },
            {
              value: 4,
             text: "4",
            },
            {
              value: 5,
             text: "5",
            },
          ],
          minRateDescription: "Strongly Agree",
          maxRateDescription: "Strongly Disagree",
        },
      ],
    },
    {
      name: "page2",
      elements: [
        {
          type: "rating",
          name: "question3",
          title: "My skin tends to be shiny throughout the day.",
          isRequired: true,
          autoGenerate: false,
          rateValues: [
            {
              value: 1,
              text: "1",
            },
            {
              value: 2,
              text: "2",
            },
            {
              value: 3,
              text: "3",
            },
            {
              value: 4,
              text: "4",
            },
            {
              value: 5,
              text: "5",
            },
          ],
          rateMin: 1,
          minRateDescription: "Strongly Agree",
          maxRateDescription: "Strongly  Disagree",
        },
        {
          type: "rating",
          name: "question4",
          title: "My skin feels tight and rough, especially after cleansing",
          isRequired: true,
          rateValues: [
            {
              value: 1,
              text: "1",
            },
            {
              value: 2,
              text: "2",
            },
            {
              value: 3,
              text: "3",
            },
            {
              value: 4,
              text: "4",
            },
            {
              value: 5,
              text: "5",
            },
          ],
          minRateDescription: "Strongly Agree",
          maxRateDescription: "Strongly Disagree",
        },
      ],
    },
    {
      name: "page3",
      elements: [
        {
          type: "rating",
          name: "question5",
          title:
            "I have dry patches or areas on my skin, particularly on the cheeks or around the eyes.",
          isRequired: true,
          autoGenerate: false,
          rateValues: [
            {
              value: 1,
              text: "1",
            },
            {
              value: 2,
              text: "2",
            },
            {
              value: 3,
              text: "3",
            },
            {
              value: 4,
              text: "4",
            },
            {
              value: 5,
              text: "5",
            },
          ],
          rateMin: 1,
          minRateDescription: "Strongly Agree",
          maxRateDescription: "Strongly  Disagree",
        },
        {
          type: "rating",
          name: "question6",
          title:
            "My skin becomes red or irritated easily, especially when using new products.",
          isRequired: true,
          rateValues: [
            {
              value: 1,
              text: "1",
            },
            {
              value: 2,
              text: "2",
            },
            {
              value: 3,
              text: "3",
            },
            {
              value: 4,
              text: "4",
            },
            {
              value: 5,
              text: "5",
            },
          ],
          minRateDescription: "Strongly Agree",
          maxRateDescription: "Strongly Disagree",
        },
      ],
    },
    {
      name: "page4",
      elements: [
        {
          type: "rating",
          name: "question7",
          title: "I have noticeable pores, especially on my nose and forehead.",
          isRequired: true,
          autoGenerate: false,
          rateValues: [
            {
              value: 1,
              text: "1",
            },
            {
              value: 2,
              text: "2",
            },
            {
              value: 3,
              text: "3",
            },
            {
              value: 4,
              text: "4",
            },
            {
              value: 5,
              text: "5",
            },
          ],
          rateMin: 1,
          minRateDescription: "Strongly Agree",
          maxRateDescription: "Strongly  Disagree",
        },
        {
          type: "rating",
          name: "question8",
          title:
            "Different parts of my face have different textures (oily, dry, normal).",
          isRequired: true,
          rateValues: [
            {
              value: 1,
              text: "1",
            },
            {
              value: 2,
              text: "2",
            },
            {
              value: 3,
              text: "3",
            },
            {
              value: 4,
              text: "4",
            },
            {
              value: 5,
              text: "5",
            },
          ],
          minRateDescription: "Strongly Agree",
          maxRateDescription: "Strongly Disagree",
        },
      ],
    },
    {
      name: "page5",
      elements: [
        {
          type: "rating",
          name: "question9",
          title:
            "My skin is easily irritated by skincare products or environmental factors.",
          isRequired: true,
          autoGenerate: false,
          rateValues: [
            {
              value: 1,
              text: "1",
            },
            {
              value: 2,
              text: "2",
            },
            {
              value: 3,
              text: "3",
            },
            {
              value: 4,
              text: "4",
            },
            {
              value: 5,
              text: "5",
            },
          ],
          rateMin: 1,
          minRateDescription: "Strongly Agree",
          maxRateDescription: "Strongly  Disagree",
        },
        {
          type: "rating",
          name: "question10",
          title:
            "I rarely experience breakouts or dryness, and my skin feels balanced most of the time.",
          isRequired: true,
          rateValues: [
            {
              value: 1,
              text: "1",
            },
            {
              value: 2,
              text: "2",
            },
            {
              value: 3,
              text: "3",
            },
            {
              value: 4,
              text: "4",
            },
            {
              value: 5,
              text: "5",
            },
          ],
          minRateDescription: "Strongly Agree",
          maxRateDescription: "Strongly Disagree",
          startWithNewLine: true,
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
};