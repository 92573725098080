import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Layout/Loader";
import DermatologistCard from "./DermatologistCard";
import styles from "../../styles/styles";


const DermatologistList = () => {
  const [data, setData] = useState([]);
  const { dermatologists, isLoading } = useSelector((state) => state.dermatologist);

  console.log("🚀 ~ file: DermatologistList.jsx:14 ~ DermatologistList ~ dermatologists:", dermatologists)
  

  useEffect(() => {
    const allDermatologistsData = dermatologists ? [...dermatologists] : [];
    setData(allDermatologistsData);
  }, [dermatologists]);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <br />
          <div className={`w-full mx-auto`}>
            {data && data.length === 0 && (
              <h1 className="text-center my-5 font-semibold text-lg">
                No Registered Dermatologists Currently!
              </h1>
            )}
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-4 xl:gap-[30px] mb-12">
              {data &&
                data?.length > 0 &&
                data.map((i, index) => <DermatologistCard dermatologist={i} key={index} />)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DermatologistList;
