import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import styles from '../../styles/styles'
import EventCard from "./EventCard";

const Events = () => {
  const {allEvents,isLoading} = useSelector((state) => state?.events);  
   
  return (
    <div>
      {!isLoading && (
        <div className={`w-9/12 mx-auto`}>
          <div
            className={`text-center md:text-start mb-5 text-4xl font-light uppercase`}
          >
            <h1>POPULAR EVENTS</h1>
          </div>

          <div className="w-full grid">
            {allEvents?.length !== 0 && (
              <EventCard data={allEvents && allEvents[0]} />
            )}
            <h4>{allEvents?.length === 0 && "No Events Currently!"}</h4>
          </div>
        </div>
      )}
    </div>
  );
}

export default Events