import { createReducer } from "@reduxjs/toolkit";

const initialState = {
isLoading: true,
error: null,
success: false,
blog: null,
successMessage: null,
blogs: [],

};

export const blogReducer = createReducer(initialState, {
  blogCreateRequest: (state) => {
    state.isLoading = true;
  },
  blogCreateSuccess: (state, action) => {
    // console.log("🚀 ~ file: blog.js:12 ~ action:", action)
    state.isLoading = false;
    state.blog = action.payload;
    state.success = true;
    state.successMessage = "Blog Post created successfully";
    state.blogs = [...state.blogs, action.payload];
  },

  blogCreateFail: (state, action) => {
  //  console.log("🚀 ~ file: blog.js:21 ~ action:", action)
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // update blog
  blogUpdateRequest: (state) => {
    state.isLoading = true;
  },
  blogUpdateSuccess: (state, action) => {
    state.isLoading = false;
    state.blog = action.payload;
    state.success = true;
    state.successMessage = "Blog Post updated successfully";
  },
  blogUpdateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },
  

  // get all blogs of shop
  getAllBlogsShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllBlogsShopSuccess: (state, action) => {
    state.isLoading = false;
    state.blogs = action.payload;
  },
  getAllBlogsShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },


  // delete blog of a shop
  deleteBlogRequest: (state) => {
    state.isLoading = true;
  },
  deleteBlogSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteBlogFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all blogs
  getAllBlogsRequest: (state) => {
    state.isLoading = true;
  },
  getAllBlogsSuccess: (state, action) => {
    state.isLoading = false;
    state.blogs = action.payload;
  },
  getAllBlogsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
  clearMessages: (state) => {
    state.successMessage = null;
  },
});
