import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link} from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

//icons
import { AiOutlineArrowRight } from "react-icons/ai";
import { HiOutlineLightBulb } from "react-icons/hi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import  { FaUserDoctor } from "react-icons/fa6";
import { FiShoppingBag } from "react-icons/fi";
import { BiUserCheck } from "react-icons/bi";
import { PiGraduationCapBold } from "react-icons/pi";
import { MdOutlineQuiz, MdCastForEducation } from "react-icons/md";
import { GoDiscussionClosed } from "react-icons/go";

//import Announcements from "../components/Layout/Announcements";
import StoreListingHero from "../components/utils/StoreListingHero";


const ServicesPage = () => {


  return (
    <>
      <Header />

      <div>
        <StoreListingHero
          title="Our Services"
          imageUrl="https://img.freepik.com/free-vector/service-24-7-concept-illustration_114360-7380.jpg?w=740&t=st=1699896208~exp=1699896808~hmac=a7eae446ca48c81075d59a96bac21203ba9208b866de31ffec46411b23bcb25b"
        />
        <br />
        <br />
        <div className={`w-9/12 mx-auto`}>
          <section className="text-black">
            <div className="mx-auto  px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
              <div className="mx-auto  text-center">
                <h2 className="text-3xl font-bold sm:text-4xl">
                  Empowering Your Skin Care Journey
                </h2>

                <p className="mt-4 text-xl text-gray-700">
                  Discover our wide range of services from precise skin tone
                  analysis, personalized product recommendations, expert
                  dermatologist advice, to seamless shopping of trusted brands.
                  Empower yourself with user-centric features, make informed
                  decisions with reviews, and understand your skin better with
                  our quizzes. Start your journey with us today! 😊
                </p>
              </div>

              <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
                <Link
                  to={""}
                  className="block rounded-xl border  p-8 shadow-xl transition hover:border-gray-300 hover:shadow-[#fed7cc]"
                >
                  <HiOutlineLightBulb className="h-10 w-10 text-[#e50a3e]" />

                  <h2 className="mt-4 text-2xl font-bold text-black">
                    Skin Tone Precision
                  </h2>

                  <p className="mt-1 text-lg text-gray-800">
                    Unlock your radiant skin with our precision-based approach.
                    We analyze your unique skin tone to provide you with the
                    most effective products that enhance your natural beauty and
                    promote healthy, glowing skin.
                  </p>
                </Link>
                <Link
                  to={""}
                  className="block rounded-xl border p-8 shadow-xl transition hover:border-gray-300 hover:shadow-[#fed7cc]"
                >
                  <MdOutlinePersonalInjury className="h-10 w-10 text-[#e50a3e]" />
                  <h2 className="mt-4 text-2xl font-bold text-black">
                    Personalized Recommendations
                  </h2>

                  <p className="mt-1 text-lg text-gray-800">
                    Discover your perfect products, tailored just for you. Our
                    advanced algorithm takes into account your skin type,
                    concerns, and preferences to curate a personalized selection
                    of skin care products that will work best for you.
                  </p>
                </Link>
                <Link
                  to={""}
                  className="block rounded-xl border p-8 shadow-xl transition hover:border-gray-300 hover:shadow-[#fed7cc]"
                >
                  <FaUserDoctor className="h-10 w-10 text-[#e50a3e]" />

                  <h2 className="mt-4 text-2xl font-bold text-black">
                    Dermatologist Expertise
                  </h2>

                  <p className="mt-1 text-lg text-gray-800">
                    Get expert guidance at your fingertips. Our platform
                    connects you with experienced dermatologists who can provide
                    professional advice, answer your questions, and guide you on
                    your skin care journey.
                  </p>
                </Link>
                <Link
                  to={""}
                  className="block rounded-xl border  p-8 shadow-xl transition hover:border-gray-300 hover:shadow-[#fed7cc]"
                >
                  <FiShoppingBag className="h-10 w-10 text-[#e50a3e]" />

                  <h2 className="mt-4 text-2xl font-bold text-black">
                    Seamless Shopping
                  </h2>

                  <p className="mt-1 text-lg text-gray-800">
                    Enjoy the convenience of shopping trusted brands directly
                    from our app. We offer a wide range of dermatology and skin
                    care products from reputable brands, ensuring you get only
                    the best for your skin.
                  </p>
                </Link>
                <Link
                  to={""}
                  className="block rounded-xl border p-8 shadow-xl transition hover:border-gray-300 hover:shadow-[#fed7cc]"
                >
                  <BiUserCheck className="h-10 w-10 text-[#e50a3e]" />

                  <h2 className="mt-4 text-2xl font-bold text-black">
                    User Empowerment
                  </h2>

                  <p className="mt-1 text-lg text-gray-800">
                    Take control of your skin care. Save your preferences on our
                    platform for personalized recommendations that align with
                    your skin care goals. Your skin, your rules.
                  </p>
                </Link>
                <Link
                  to={""}
                  className="block rounded-xl border  p-8 shadow-xl transition hover:border-gray-300 hover:shadow-[#fed7cc]"
                >
                  <PiGraduationCapBold className="h-10 w-10 text-[#e50a3e]" />

                  <h2 className="mt-4 text-2xl font-bold text-black">
                    Informed Decisions
                  </h2>

                  <p className="mt-1 text-lg text-gray-800">
                    Make confident purchases backed by reviews. Our platform
                    features product reviews and ratings from other users,
                    providing you with valuable insights to help you make
                    informed decisions.
                  </p>
                </Link>
                <Link
                  to={""}
                  className="block rounded-xl border p-8 shadow-xl transition hover:border-gray-300 hover:shadow-[#fed7cc]"
                >
                  <MdOutlineQuiz className="h-10 w-10 text-[#e50a3e]" />
                  <h2 className="mt-4 text-2xl font-bold text-black">
                    Skin Insights Quizzes
                  </h2>

                  <p className="mt-1 text-lg text-gray-800">
                    Know your skin, love your skin. Our interactive skin quizzes
                    provide valuable insights about your skin type and
                    condition, helping you understand your skin better and
                    choose the right products.
                  </p>
                </Link>
                <Link
                  to={""}
                  className="block rounded-xl border  p-8 shadow-xl transition hover:border-gray-300 hover:shadow-[#fed7cc]"
                >
                  <GoDiscussionClosed className="h-10 w-10 text-[#e50a3e]" />

                  <h2 className="mt-4 text-2xl font-bold text-black">
                    Virtual Consultations
                  </h2>

                  <p className="mt-1 text-lg text-gray-800">
                    Get personalized advice from the comfort of your home. Our
                    virtual consultations allow you to connect with
                    dermatologists and skin care experts for professional advice
                    tailored to your specific needs.
                  </p>
                </Link>{" "}
                <Link
                  to={""}
                  className="block rounded-xl border p-8 shadow-xl transition hover:border-gray-300 hover:shadow-[#fed7cc]"
                >
                  <MdCastForEducation className="h-10 w-10 text-[#e50a3e]" />

                  <h2 className="mt-4 text-2xl font-bold text-black">
                    Skin Care Education
                  </h2>

                  <p className="mt-1 text-lg text-gray-800">
                    Learn more about skin care with our educational resources.
                    We provide articles, videos, and tutorials on various skin
                    care topics to help you understand your skin better and make
                    informed decisions about your skin care routine.
                  </p>
                </Link>
              </div>

              <div className="mt-12 text-center">
                <Link to={"/login"}>
                  <button
                    type="button"
                    className="bg-theme-button-bg my-10 text-xl  text-white font-semibold py-4 px-8 rounded-full hover:bg-black hover:text-white"
                  >
                    Start Your Journey
                    <AiOutlineArrowRight className="inline ml-2" />
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </div>

        {/* <Footer /> */}
      </div>

      <Footer />
    </>
  );
};

export default ServicesPage;
