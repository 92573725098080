
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar, Collapse, Button, IconButton } from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import Logo from "../../Assests/images/Logo.png";
import NavList from "./HeaderMenus/NavListMenu";
import ProfileMenu from "./HeaderMenus/ProfileMenu";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";

export default function Header() {
  const [openNav, setOpenNav] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <>
      <Navbar fullWidth className="mx-auto w-full px-4 py-2 shadow-transparent  border-0 sticky top-0 z-50">
        <div className="flex items-center justify-between text-blue-gray-900">
          <div>
            <Link to="/">
              <img
                src={Logo}
                className="w-[150px] h-[80px] md:w-[250px] md:h-[120px] object-cover"
                alt=""
              />
            </Link>
          </div>

          <div className="hidden lg:block">
            <NavList />
          </div>
          <div className="hidden gap-2 lg:flex">
            <div
              className="relative cursor-pointer mr-[15px]"
              onClick={() => setOpenWishlist(true)}
            >
              <AiOutlineHeart size={30} color="black" />
              <span className="absolute right-0 top-0 rounded-full bg-[#dc143c] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                {wishlist && wishlist.length}
              </span>
            </div>
            <div
              className="relative cursor-pointer mr-[15px]"
              onClick={() => setOpenCart(true)}
            >
              <AiOutlineShoppingCart size={30} color="black" />
              <span className="absolute right-0 top-0 rounded-full bg-[#dc143c] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                {cart && cart.length}
              </span>
            </div>
            <ProfileMenu />
          </div>
          <IconButton
            variant="text"
            color="blue-gray"
            className="lg:hidden"
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="h-6 w-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="h-6 w-6" strokeWidth={2} />
            )}
          </IconButton>
        </div>

        <Collapse open={openNav}>
          <NavList />
          <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
            {/* <Button variant="outlined" size="lg" color="blue-gray" fullWidth>
            Log In
          </Button> */}
            <div className={`flex items-center`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={() => setOpenWishlist(true)}
              >
                <AiOutlineHeart size={30} color="black" />
                <span className="absolute right-0 top-0 rounded-full bg-[#dc143c] w-4 h-4 top right p-0 m-0 text-white  font-mono text-[12px] leading-tight text-center">
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>
            <div className={`flex items-center`}>
              <div
                className="relative cursor-pointer mr-[15px]"
                onClick={() => setOpenCart(true)}
              >
                <AiOutlineShoppingCart size={30} color="black" />
                <span className="absolute right-0 top-0 rounded-full bg-[#dc143c] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {cart && cart.length}
                </span>
              </div>
            </div>
            <ProfileMenu />
          </div>
        </Collapse>
      </Navbar>

      {/* cart popup */}
      {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

      {/* wishlist popup */}
      {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
    </>
  );
}


