import { useState,  useMemo } from "react";
 import HeroImage from "../../../Assests/hero/HeroImage.jpeg";
  import DermatologistHero from "../../../Assests/hero/DermatologistHero.jpg";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import RightArrow from "../../../Assests/icons/RightArrow.png";
import LeftArrow from "../../../Assests/icons/LeftArrow.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { css } from "styled-components";


export const mobile = (props) => {
  return css`
    @media only screen and (max-width: 380px) {
      ${props}
    }
  `;
};


const Container = styled.div`
  width: 100%;
  height: 77vh;
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobile({ display: "none" })}
`;

// const Arrow = styled.div`
//   width: 50px;
//   height: 50px;
//   // background-color: #fff7f7;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: ${(props) => props.direction === "left" && "10px"};
//   right: ${(props) => props.direction === "right" && "10px"};
//   margin: auto;
//   cursor: pointer;
//   opacity: 0.5;
//   z-index: 2;
// `;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #${(props) => props.background};
`;

const ImgContainer = styled.div`
  height: 100vh;
  flex: 1;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 40px;
`;

const Title = styled.h1`
  font-size: 35px;
`;

const Desc = styled.p`
  font-weight: 400;
`;

const Button = styled.button`
  padding: 10px;
`;

const Hero = () => {
  const [slideIndex, setSlideIndex] = useState(0);

    const images = useMemo(
      () => [
        {
          id: "11111",
          background: "#f0fff9",
          url: "https://images.unsplash.com/photo-1616754344345-222952932975?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
          text: "Experience Luxury Beauty",
          paragraph:
            "Step into a world of skin transformation with our premium beauty products. Hand-selected by our expert dermatologists, each product promises to deliver exceptional results while pampering your skin. Dive in to discover the perfect additions to your skincare routine. ",
          sectionLink: "products",
          textButton: "Shop Now",
        },
        {
          id: "22222",

          background: "#fcf1ed",
          url: HeroImage,
          text: "Discover Your Perfect Glow with Accurate Skin Tone Identification",
          paragraph:
            "Take our comprehensive skin questionnaire and discover your skin type and its specific needs. Our questionnaire is designed by dermatologists to help you understand your skin better and recommend personalized skincare routines and products. Start your journey to healthier skin today! ",
          sectionLink: "skin-quiz",
          textButton: "Take the Skin Quiz",
        },

        {
          id: "33333",
           background: "#f5fafd",
           url: DermatologistHero,
          // url: "https://images.unsplash.com/photo-1631730359585-38a4935cbec4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
         // url: "https://img.freepik.com/free-photo/doctor-nurses-special-equipment_23-2148980721.jpg?w=996&t=st=1698231708~exp=1698232308~hmac=70d6d8a16a12b96d5b5715a3537517604dbc91256e1b4f05f31e6f6ee04e0b1b ",
          text: "Welcome to Your Skin’s Best Friend!",
          paragraph:
            "We believe in the power of healthy skin. Our team of experienced dermatologists is dedicated to providing personalized care that meets your unique skin needs. We offer a wide range of services, from acne treatments to anti-aging solutions, all designed to help you achieve and maintain healthy, beautiful skin. ",
          sectionLink: "dermatologist",
          textButton: "Discover Our Dermatologists",
        },
      ],
      []
    );

  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
    } else {
      setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
    }
  };

  return (
    <Container>
      <Arrow direction="left" onClick={() => handleClick("left")}>
        {/* <AiOutlineArrowLeft /> */}
        <img src={LeftArrow} alt="" />
      </Arrow>
      {/* <img src={LeftArrow} alt="" className="w-[15px] h-[15px]" /> */}
      <Wrapper slideIndex={slideIndex}>
        {images?.length > 0
          ? images?.map((item) => (
              <Slide bg={item?.background} key={item.id}>
                <ImgContainer>
                  <Image src={item.url} />
                </ImgContainer>
                <InfoContainer>
                  <Title className="-mt-20 uppercase  text-[35px] leading-[1.2] text-[#3d3a3a] font-[600] ">
                    {item.text}
                  </Title>
                  <Desc className=" md:block text-[16px] font-[Poppins] font-[400] text-[#000000ba] mt-5">
                    {item.paragraph}
                  </Desc>
                  <Link to={`/${item.sectionLink}`}>
                    <Button className="bg-black h-[50px] rounded text-white text-[18px] font-[Poppins] cursor-pointer mt-5">
                      {item.textButton}
                    </Button>
                  </Link>
                </InfoContainer>
              </Slide>
            ))
          : ""}
      </Wrapper>
      <Arrow direction="right" onClick={() => handleClick("right")}>
        {/* <AiOutlineArrowRight /> */}
        <img src={RightArrow} alt="" />
      </Arrow>
    </Container>
  );
};

export default Hero;

