import React from "react";
//import Announcements from "./../../components/Layout/Announcements";
import Footer from "./../../components/Layout/Footer";
import Header from "./../../components/Layout/Header";
import StoreListingHero from "./../../components/utils/StoreListingHero";

import SkinQuizComponent from "../../components/Dermatologist/quiz/SkinQuizComponent";

const SkinQuizPage = () => {
  return (
    <div>
      <div>
        {/* <Announcements /> */}
        <Header activeHeading={4} />
        <StoreListingHero
          title={"Quizzical Glow"}
          imageUrl="https://img.freepik.com/free-vector/team-checklist-concept-illustration_114360-13202.jpg?w=1380&t=st=1696415463~exp=1696416063~hmac=f2dfdbf13b6a1f033bba74768dcac9e514f011261f53a196d7508cd69229c526"
        />
        <div className="py-10 bg-theme-bg-thin flex flex-col items-center justify-center">
          {" "}
          <div>
            <h1 className="mt-5 text-center font-semibold text-4xl italic">
              Discover Your Ideal Skincare Products
            </h1>
          </div>
          <div className="mt-5">
            <h3 className="text-center font-light text-3xl">
              Take Our New Quiz to Find The Best Products for Your
              <br />
              Skin-Care Routine
            </h3>
          </div>
          <div className="mt-5">
            <h4 className="text-center font-light text-2xl">
              Introducing V 1.0 of GlowGenie  skin-care quiz, an interactive
              questionnaire that puts our skin care and product know-how into
              one, <br />
              easy-to-use streamlined system.
            </h4>
          </div>
        </div>

        <div ><SkinQuizComponent/></div>

        <Footer />
      </div>
    </div>
  );
};

export default SkinQuizPage;