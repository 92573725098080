import React, {useEffect} from "react";

import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";


import ReactCompareImage from "react-compare-image";

import WomanBefore from "../../../Assests/beforeafter/WomanBefore.jpg";
import WomanAfter from "../../../Assests/beforeafter/WomanAfter.jpg";
import ManBefore from "../../../Assests/beforeafter/ManBefore.jpg";
import ManAfter from "../../../Assests/beforeafter/ManAfter.jpg";


const CustomHandle = () => (
  <div
    style={{
      width: "100px",
      height: "100px",
      background: "rgba(255, 255, 255, 0.5)",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "black",
      fontWeight: "bold",
      cursor: "ew-resize",
    }}
  >
    Drag Here
  </div>
);


// const BeforeAfterSlider = () => {
//   const imagePairs = [
//     {
//       firstImage: WomanBefore,
//       secondImage: WomanAfter,
//     },
//     {
//       firstImage: ManBefore,
//       secondImage: ManAfter,
//     },
//   ];

//   return (
//     <>
//       <div className="w-9/12 mx-auto my-5 z-0">
//         <div className="text-center mb-2 text-4xl font-light uppercase mt-20">
//           <h2>REAL TIME BEFORE & AFTER GALLERY</h2>
//         </div>
//         <div className="text-center mb-5 text-2xl font-light">
//           <h5>Results of our Transformative Skincare Journeys </h5>
//         </div>
//         <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px]">
//           {imagePairs &&
//             imagePairs.map((imagePair, index) => (
//               <div key={index}>
//                 <div>
//                   <ReactBeforeSliderComponent
//                     firstImage={{ imageUrl: imagePair.firstImage }}
//                     secondImage={{ imageUrl: imagePair.secondImage }}
//                     delimiterColor="#f8aca3"
//                     handle={<CustomHandle />}
//                   />
//                 </div>
//               </div>
//             ))}
//         </div>
//       </div>
//     </>
//   );
// };



  const imagePairs = [
    {
      firstImage: WomanBefore,
      secondImage: WomanAfter,
    },
    {
      firstImage: ManBefore,
      secondImage: ManAfter,
    },
  ];

const BeforeAfterSlider = () => {
useEffect(() => {
  window.addEventListener("error", (e) => {
    if (e.message.includes("Resizeobserver loop completed with undelivered notifications")) {
      const resizeObserverErrDiv = document.getElementById(
        "webpack-dev-server-client-overlay-div"
      );
      const resizeObserverErr = document.getElementById(
        "webpack-dev-server-client-overlay"
      );
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute("style", "display: none");
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute("style", "display: none");
      }
    }
  });
}, []);




  return (
    <div className="w-9/12 mx-auto my-5 z-0">
      <div className="text-center mb-2 text-4xl font-light uppercase mt-20">
        <h2>REAL TIME BEFORE & AFTER GALLERY</h2>
      </div>
      <div className="text-center mb-5 text-2xl font-light">
        <h5>Results of our Transformative Skincare Journeys </h5>
      </div>
      <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px]">
        {imagePairs.map((pair, index) => (
          <ReactCompareImage
            key={index}
            leftImage={pair.firstImage}
            rightImage={pair.secondImage}
            handle={<CustomHandle />}
          />
        ))}
      </div>
    </div>
  );

        }



export default BeforeAfterSlider;