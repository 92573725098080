import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import { updateBlog } from "../../redux/actions/blog";
import { getAllBlogs } from "../../redux/actions/blog";
import ReactQuill from "react-quill"; // Import react-quill
import "react-quill/dist/quill.snow.css"; 


const UpdateBlogForm = ({ postDetails, updateDetails }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [content, setContent] = useState("");

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  // console.log("🚀 ~ file: UpdateBlogForm.jsx:8 ~ UpdateBlogForm ~ postDetails:", postDetails)
  const { user } = useSelector((state) => state?.user);
  const { isDermatologist, dermatologist } = useSelector(
    (state) => state?.dermatologist
  );
  const { isSeller, seller } = useSelector((state) => state?.seller);

  const { blogs, error, success, successMessage } = useSelector(
    (state) => state?.blog
  );

  const { blog } = useSelector((state) => state?.blog);
  const id = postDetails?._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (success) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, success, successMessage]);

  //const authorId = user?._id || dermatologist?._id || seller?._id;
  // Set initial content value when postDetails change
  useEffect(() => {
    if (postDetails) {
      setContent(postDetails?.content || ""); // Set the initial content
    }
  }, [postDetails]);

  const handleDelete = async () => {
    await axios
      .delete(`${server}/blog/delete-blog/${id}`)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });

    dispatch(getAllBlogs());

    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };

  return (
    <div>
      {postDetails && (
        <Formik
          initialValues={{
            title: postDetails?.title || "",
            //content: postDetails?.content || "",
            avatar: postDetails?.avatar || "",
          }}
          onSubmit={(values, { resetForm }) => {
            const { title, content, avatar } = values;
            //console.log("🚀 ~ file: UpdateBlogForm.jsx:42 ~ UpdateBlogForm ~ values:", values)

            const authorId = user?._id || dermatologist?._id || seller?._id;
            let authorModel;

            if (user?.role === "Admin") {
              authorModel = "Admin";
            } else if (isDermatologist) {
              authorModel = "Dermatologist";
            } else if (isSeller) {
              authorModel = "Vendor";
            } else {
              toast.error("You don't have permission to perform this role");
              return;
            }

            dispatch(
              updateBlog(title, id, content, authorId, authorModel, avatar)
            );
            updateDetails();
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }}
        >
          <Form className="grid gap-4 mb-4 sm:grid-cols-2">
            <div>
              <label
                htmlFor="title"
                className="block mb-2 text-lg font-medium text-gray-900"
              >
                Title
              </label>
              <Field
                type="text"
                id="title"
                name="title"
                placeholder="Type product name"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:ring-[#f84d17] placeholder-gray-400 focus:ring-2 focus:ring-offset-1"
              />
            </div>
            {/* Other input fields */}
            {/* Add more fields as needed */}
            <div className="sm:col-span-2">
              <label
                htmlFor="content"
                className="block mb-2 text-lg font-medium text-gray-900"
              >
                Content
              </label>
              <ReactQuill
                theme="snow"
                value={content}
                onChange={(value) => setContent(value)}
                className="bg-gray-50 rounded-lg border border-gray-300 focus:ring-[#f84d17] focus:ring-2 focus:ring-offset-1 placeholder-gray-400"
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: ["small", false, "large", "huge"] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
              />
            </div>
            {/* Image upload input */}
            <div className="mt-2 flex items-center">
              <label
                htmlFor="avatar"
                className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <span>Upload Blog Image</span>
                <Field
                  type="file"
                  id="avatar"
                  name="avatar"
                  className="sr-only"
                />
              </label>
            </div>
            {/* Buttons */}
            <div className="sm:col-span-2">
              <button
                type="submit"
                className={`w-full mt-5 rounded-md border border-red-500 text-[#f84d17] px-5 py-2.5 text-center text-lg font-medium hover:bg-[#f84d17] hover:text-white`}
              >
                Update Post
              </button>
            </div>
            <div className="sm:col-span-2">
              <button
                onClick={toggleDeleteModal}
                type="button" // Change type to "button"
                className={`w-full mt-5 rounded-md border border-red-500 text-[#f84d17] px-5 py-2.5 text-center text-lg font-medium hover:bg-[#f84d17] hover:text-white`}
              >
                Delete Post
              </button>
            </div>{" "}
          </Form>
        </Formik>
      )}
      {deleteModalOpen && (
        <>
          {" "}
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div
            id="deleteModal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 flex shadow-lg overflow-y-auto overflow-x-hidden mx-auto my-auto z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full"
          >
            <div className="relative p-4 w-full max-w-md h-full md:h-auto">
              <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <button
                  type="button"
                  onClick={toggleDeleteModal}
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="deleteModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <svg
                  className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <p className="mb-4 text-gray-900 dark:text-gray-300">
                  Are you sure you want to delete this post?
                </p>
                <div className="flex justify-center items-center space-x-4">
                  <button
                    onClick={toggleDeleteModal}
                    data-modal-toggle="deleteModal"
                    type="button"
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    No, cancel
                  </button>
                  <button
                    onClick={handleDelete}
                    type="submit"
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                  >
                    Yes, I'm sure
                  </button>
                </div>
              </div>
            </div>
          </div>{" "}
        </>
      )}{" "}
    </div>
  );
};

export default UpdateBlogForm;
