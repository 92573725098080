import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DermatologistCreate from "../../components/Dermatologist/DermatologistCreate";

const DermatologistCreatePage = () => {
  const navigate = useNavigate();
  const { isDermatologist, dermatologist } = useSelector((state) => state?.dermatologist);

  useEffect(() => {
    if(isDermatologist === true){
      navigate(`/dermatologist/${dermatologist._id}`);
    }
  }, [])
  return (
    <div>
        <DermatologistCreate />
    </div>
  )
}

export default DermatologistCreatePage