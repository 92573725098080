import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import Sponsored from "../components/Route/Sponsored";

//import Announcements from "../components/Layout/Announcements"
import StoreListingHero from "../components/utils/StoreListingHero";
import CategoryFilter from "../components/Products/ProductsPageSidebar/Category/Category";

import PriceFilter from "../components/Products/ProductsPageSidebar/Price/Price";



const ProductsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("All");
  const {allProducts, isLoading} = useSelector((state) => state.products);
  const [data, setData] = useState([]);


  useEffect(() => { 
    const params = new URLSearchParams(window.location.search);
    const category = params.get("category");
    if (category) {
    // Decode the category to handle spaces and special characters
    const decodedCategory = decodeURIComponent(category);
    setSelectedCategory(decodedCategory);

    } else {
      setSelectedCategory("All");
    }
  }, [window.location.search]);

useEffect(() => {
  handleProductFilter();
}, [selectedCategory, selectedPrice, searchTerm]);


  
  const handleSearchChange = (e) => {

    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setData(filteredProducts)
  };



   const handlePriceFilter = (price) => {
         if (selectedPrice === "Ksh 0 - Ksh 999") {
           return price >= 0 && price <= 999;
         } else if (selectedPrice === "Ksh 1000 - Ksh 2499") {
           return price >= 1000 && price <= 2499;
         } else if (selectedPrice === "Ksh 2500 - Ksh 4999") {
           return price >= 2500 && price <= 4999;
         } else if (selectedPrice === "Ksh 5000 - Ksh 9999") {
           return price >= 5000 && price <= 9999;
         } else if (selectedPrice === "Ksh 10000 - Ksh 49999") {
           return price >= 10000 && price <= 49999;
         } else if (selectedPrice === "Above Ksh 50000") {
           return price >= 50000;
         }
         return false;
   };




const handleProductFilter = () => {
  const filteredProducts = allProducts?.filter((product) => {
    // Filter by search term
    const nameMatch = product.name.toLowerCase().includes(searchTerm.toLowerCase());

    // Filter by category
    const categoryMatch = selectedCategory === "All" || product?.category === selectedCategory;

    // Filter by price range
    const priceMatch =
      selectedPrice === "All" || handlePriceFilter(product?.discountPrice);

    return nameMatch && categoryMatch && priceMatch;
  });

  setData(filteredProducts);
};




  return (
    <>
      {isLoading ? (
        <div>
          {/* <Announcements /> */}
          <Header activeHeading={2} /> <Loader />
          <Footer />
        </div>
      ) : (
        <div>
          {/* <Announcements /> */}
          <Header activeHeading={2} />
          <StoreListingHero
            title={selectedCategory !== null ? selectedCategory : "Available Products"}
            imageUrl="https://images.unsplash.com/photo-1516975080664-ed2fc6a32937?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          />
          <br />
          <br />
          <div className={`w-11/12 mx-auto`}>
            {selectedCategory !== null && (
              <div className={`text-3xl font-light mb-2 md:text-start `}>
                <h4>{selectedCategory} Products</h4>
              </div>
            )}
            {selectedCategory === null && (
              <div className={`text-3xl font-light mb-2 md:text-start `}>
                <h4>All Products</h4>
              </div>
            )}

            <div className="flex">
              <div className="3/12 mr-5">
                <div className="my-2">
                  <CategoryFilter setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory}/>
                </div>
                <div className="my-2">
                  <PriceFilter setSelectedPrice={setSelectedPrice} />
                </div>
              </div>

              {/* search box */}
              <div className="9/12">
                <div className="w-[50%] relative my-5">
                  <input
                    type="text"
                    placeholder="Search Product..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="h-[40px] w-full px-2 border-[#f8aca3] border-[2px] rounded-md"
                  />
                  <AiOutlineSearch
                    size={30}
                    className="absolute right-2 top-1.5 cursor-pointer"
                  />
                </div>
                <div className="grid grid-cols-1 gap-[20px]  lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-4 xl:gap-[30px] mb-12">
                  {data &&
                    data.map((i, index) => (
                      <ProductCard data={i} key={index} />
                    ))}
                </div>
                {data && data.length === 0 ? (
                  <h1 className="text-center my-5 font-semibold text-lg">
                    No products Found!
                  </h1>
                ) : null}{" "}
              </div>
            </div>
          </div>

          <div
            className={`w-9/12 mx-auto text-center md:text-start mb-5 text-4xl font-light uppercase`}
          >
            <h1>OUR PARTNERS</h1>
          </div>
          <Sponsored />
          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;