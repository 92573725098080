import { useState } from "react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";

export default function PriceFilter({ setSelectedPrice}) {
  const [selectedProductPrice, setSelectedProductPrice] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handlePriceClick = (priceRange) => {
    setSelectedProductPrice(priceRange);
    setSelectedPrice(priceRange);
    // if (priceRange === "All") {
    //   setProducts(products);
    // } else {
    //   const filtered = products.filter((product) => {
    //     const price = product.discountPrice;
    //     if (priceRange === "Ksh 0 - Ksh 999") {
    //       return price >= 0 && price <= 999;
    //     } else if (priceRange === "Ksh 1000 - Ksh 2499") {
    //       return price >= 1000 && price <= 2499;
    //     } else if (priceRange === "Ksh 2500 - Ksh 4999") {
    //       return price >= 2500 && price <= 4999;
    //     } else if (priceRange === "Ksh 5000 - Ksh 9999") {
    //       return price >= 5000 && price <= 9999;
    //     } else if (priceRange === "Ksh 10000 - Ksh 49999") {
    //       return price >= 10000 && price <= 49999;
    //     } else if (priceRange === "Above Ksh 50000") {
    //       return price >= 50000;
    //     }
    //   });
    //   setProducts(filtered);
    // }
  };

  const handleMenuIconClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Card>
      <div className="flex justify-between items-center">
        <Typography
          color="black"
          className="font-medium text-black-500 px-3 py-2 text-lg"
        >
          Price
        </Typography>
        <button
          className="block md:hidden px-3 py-2 text-red-500 hover:text-red-600 focus:outline-none"
          onClick={handleMenuIconClick}
        >
          <svg
            className="h-6 w-6 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isCollapsed ? (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm16 4H4v2h16v-2z"
              />
            ) : (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z"
              />
            )}
          </svg>
        </button>
      </div>
      <List
        className={`${
          isCollapsed ? "hidden md:block" : "block"
        } md:block overflow-y-auto`}
      >
        <ListItem key={"All"} className="p-0">
          <label
            htmlFor={`price-all`}
            className="flex w-full cursor-pointer items-center px-3 py-2"
          >
            <ListItemPrefix className="mr-3">
              <Radio
                ripple={true}
                color="red"
                name="price"
                id={`price-all`}
                value={"All"}
                className="hover:before:opacity-0"
                containerProps={{
                  className: "p-0",
                }}
                checked={selectedProductPrice === "All"}
                onChange={() => handlePriceClick("All")}
              />
            </ListItemPrefix>
            <Typography
              color="blue-gray"
              className="font-medium text-blue-gray-400"
            >
              All
            </Typography>
          </label>
        </ListItem>
        <ListItem key={"Ksh 0 - Ksh 999"} className="p-0">
          <label
            htmlFor={`price-Ksh 0 - Ksh 999`}
            className="flex w-full cursor-pointer items-center px-3 py-2"
          >
            <ListItemPrefix className="mr-3">
              <Radio
                ripple={true}
                color="red"
                name="price"
                id={`price-Ksh 0 - Ksh 999`}
                value={"Ksh 0 - Ksh 999"}
                className="hover:before:opacity-0"
                containerProps={{
                  className: "p-0",
                }}
                checked={selectedProductPrice === "Ksh 0 - Ksh 999"}
                onChange={() => handlePriceClick("Ksh 0 - Ksh 999")}
              />
            </ListItemPrefix>
            <Typography
              color="blue-gray"
              className="font-medium text-blue-gray-400"
            >
              Ksh 0 - Ksh 999
            </Typography>
          </label>
        </ListItem>
        <ListItem key={"Ksh1000 - Ksh 2499"} className="p-0">
          <label
            htmlFor={`price-Ksh1000 - Ksh 2499`}
            className="flex w-full cursor-pointer items-center px-3 py-2"
          >
            <ListItemPrefix className="mr-3">
              <Radio
                ripple={true}
                color="red"
                name="price"
                id={`price-Ksh 1000 - Ksh 2499`}
                value={"Ksh 1000 - Ksh 2499"}
                className="hover:before:opacity-0"
                containerProps={{
                  className: "p-0",
                }}
                checked={selectedProductPrice === "Ksh 1000 - Ksh 2499"}
                onChange={() => handlePriceClick("Ksh 1000 - Ksh 2499")}
              />
            </ListItemPrefix>
            <Typography
              color="blue-gray"
              className="font-medium text-blue-gray-400"
            >
              Ksh 1000 - Ksh 2499
            </Typography>
          </label>
        </ListItem>
        <ListItem key={"Ksh 2500 - Ksh 4999"} className="p-0">
          <label
            htmlFor={`price-Ksh 2500 - Ksh 4999`}
            className="flex w-full cursor-pointer items-center px-3 py-2"
          >
            <ListItemPrefix className="mr-3">
              <Radio
                ripple={true}
                color="red"
                name="price"
                id={`price-Ksh 2500 - Ksh 4999`}
                value={"Ksh 2500 - Ksh 4999"}
                className="hover:before:opacity-0"
                containerProps={{
                  className: "p-0",
                }}
                checked={selectedProductPrice === "Ksh 2500 - Ksh 4999"}
                onChange={() => handlePriceClick("Ksh 2500 - Ksh 4999")}
              />
            </ListItemPrefix>
            <Typography
              color="blue-gray"
              className="font-medium text-blue-gray-400"
            >
              Ksh 2500 - Ksh 4999
            </Typography>
          </label>
        </ListItem>
        <ListItem key={"Ksh 5000 - Ksh 9999"} className="p-0">
          <label
            htmlFor={`price-Ksh 5000 - Ksh 9999`}
            className="flex w-full cursor-pointer items-center px-3 py-2"
          >
            <ListItemPrefix className="mr-3">
              <Radio
                ripple={true}
                color="red"
                name="price"
                id={`price-Ksh 5000 - Ksh 9999`}
                value={"Ksh 5000 - Ksh 9999"}
                className="hover:before:opacity-0"
                containerProps={{
                  className: "p-0",
                }}
                checked={selectedProductPrice === "Ksh 5000 - Ksh 9999"}
                onChange={() => handlePriceClick("Ksh 5000 - Ksh 9999")}
              />
            </ListItemPrefix>
            <Typography
              color="blue-gray"
              className="font-medium text-blue-gray-400"
            >
              Ksh 5000 - Ksh 9999
            </Typography>
          </label>
        </ListItem>
        <ListItem key={"Ksh 10000 - Ksh 49999"} className="p-0">
          <label
            htmlFor={`price-Ksh 10000 - Ksh 49999`}
            className="flex w-full cursor-pointer items-center px-3 py-2"
          >
            <ListItemPrefix className="mr-3">
              <Radio
                ripple={true}
                color="red"
                name="price"
                id={`price-Ksh 10000 - Ksh 49999`}
                value={"Ksh 10000 - Ksh 49999"}
                className="hover:before:opacity-0"
                containerProps={{
                  className: "p-0",
                }}
                checked={selectedProductPrice === "Ksh 10000 - Ksh 49999"}
                onChange={() => handlePriceClick("Ksh 10000 - Ksh 49999")}
              />
            </ListItemPrefix>
            <Typography
              color="blue-gray"
              className="font-medium text-blue-gray-400"
            >
              Ksh 10000 - Ksh 49999
            </Typography>
          </label>
        </ListItem>
        <ListItem key={"Above Ksh 50000"} className="p-0">
          <label
            htmlFor={`price-Above Ksh 50000`}
            className="flex w-full cursor-pointer items-center px-3 py-2"
          >
            <ListItemPrefix className="mr-3">
              <Radio
                ripple={true}
                color="red"
                name="price"
                id={`price-Above Ksh 50000`}
                value={"Above Ksh 50000"}
                className="hover:before:opacity-0"
                containerProps={{
                  className: "p-0",
                }}
                checked={selectedProductPrice === "Above Ksh 50000"}
                onChange={() => handlePriceClick("Above Ksh 50000")}
              />
            </ListItemPrefix>
            <Typography
              color="blue-gray"
              className="font-medium text-blue-gray-400"
            >
              Above Ksh 50000
            </Typography>
          </label>
        </ListItem>
      </List>
    </Card>
  );
}
