import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaArrowRight,
  FaMapMarkerAlt,
  FaChevronLeft,
  FaChevronRight,
  FaScroll,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getAllSellers } from "../../../redux/actions/sellers";
import { StoreCard } from './Stores';

import StoreShowcase from "../../../Assests/hero/StoreShowcase.jpg";
import { Store } from "@mui/x-data-grid/utils/Store";



const SellerOfTheMonth = () => {
    const dispatch = useDispatch();
    const { sellers } = useSelector((state) => state?.seller);
    const [topSellers, setTopSellers] = useState([]);
   // console.log("🚀 ~ file: SellerOfTheMonth.jsx:125 ~ SellerOfTheMonth ~ sellers:", sellers)

    useEffect(() => {
      dispatch(getAllSellers());
    }, []);

  useEffect(() => {

    if(sellers?.length > 0){
         let sellersCopy = [...sellers];

         // Sort the copy of the sellers array by the number of transactions in descending order

         let sortedSellers = sellersCopy.sort(
           (a, b) => b.transections.length - a.transections.length
         );
         setTopSellers(sortedSellers.slice(0, 3));
    }
 
    }, [sellers]);

    
  return (
    <section>
      <div className="w-9/12 px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
        <header className="text-center">
          <h2 className="text-4xl font-bold text-gray-900 ">
            Seller of the Month
          </h2>

          <p className="max-w-md mx-auto mt-4 text-2xl text-gray-500">
            Find the best deals from our sellers of the month
          </p>
        </header>

        <ul className="grid grid-cols-1 gap-4 mt-8 lg:grid-cols-3">
          <li>
            <Link
              to={`/shop/preview/${topSellers[0]?._id}`}
              className="relative block group"
            >
              <img
                src={topSellers[0]?.avatar?.url}
                alt=""
                className="object-cover w-full transition duration-500 aspect-square group-hover:opacity-90 h-[350px]"
              />

              <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                <h3 className="text-xl font-medium text-[#f84d17]">
                  {topSellers[0]?.name}
                </h3>

                <span className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                  View Store
                </span>
              </div>
            </Link>
          </li>

          <li>
            <Link
              to={`/shop/preview/${topSellers[1]?._id}`}
              className="relative block group"
            >
              <img
                src={topSellers[1]?.avatar?.url}
                alt=""
                className="object-cover w-full transition duration-500 aspect-square group-hover:opacity-90 h-[350px]"
              />

              <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                <h3 className="text-xl font-medium text-[#f84d17]">
                  {topSellers[1]?.name}
                </h3>

                <span className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                  View Store
                </span>
              </div>
            </Link>
          </li>

          <li className="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
            <Link to={`/stores`} className="relative block group">
              <img
                src={StoreShowcase}
                alt=""
                className="object-cover w-full transition duration-500 aspect-square group-hover:opacity-90 h-[720px]"
              />

              <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                <h3 className="text-xl font-medium text-[#f84d17]">
                  Explore Our Top Vendors
                </h3>

                <span className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                  Checkout our Vendors
                </span>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default SellerOfTheMonth;
