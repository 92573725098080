import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loader from "../components/Layout/Loader";

const DermatologistProtectedRoute = ({ children }) => {
  const dermState = useSelector((state) => state?.dermatologist);
  //console.log("🚀 ~ file: DermatologistProtectedRoute.js:7 ~ DermatologistProtectedRoute ~ dermState:", dermState)
  const { isLoading, isDermatologist } = useSelector((state) => state?.dermatologist);
  console.log("🚀 ~ file: DermatologistProtectedRoute.js:7 ~ DermatologistProtectedRoute ~ isDermatologist:", isDermatologist)
  if (isLoading === true) {
    return <Loader />;
  } else {
    if (!isDermatologist) {
      return <Navigate to={`/dermatologist-login`} replace />;
    }
    return children;
  }
};

export default DermatologistProtectedRoute;
