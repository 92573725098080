import DermatologistDashboardHeader from '../../components/Dermatologist/DermatologistDashboardHeader'
import DermatologistDashboardSideBar from '../../components/Dermatologist/DermatologistDashboardSideBar'
import DermatologistDashboardMessages from "../../components/Dermatologist/DermatologistDashboardMessages";

const DermatologistInboxPage = () => {
  return (
    <div>
      <DermatologistDashboardHeader />
      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <DermatologistDashboardSideBar active={8} />
        </div>
        <DermatologistDashboardMessages />
      </div>
    </div>
  );
}

export default DermatologistInboxPage