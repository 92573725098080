import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { categoriesData } from "../../../static/data";



// export default Categories;
import Slider from "react-slick";

const Categories = () => {
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    lazyLoad: true,
    pauseOnHover: true,
    swipeToSlide: true,
    autoplay: true,
    centerPadding: "60px",
    focusOnSelect: true,
    slidesToShow: 4,
    slidesToScroll: 3,
    rtl: false,
    cssEase: "linear",
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrow: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className=" w-9/12 mx-auto my-20">
      <div className="text-center mb-5 text-4xl font-light uppercase">
        <h2>EXPLORE POPULAR CATEGORIES</h2>
      </div>
      <section className=" mt-10">
        <Slider {...settings}>
          {categoriesData.map((category, idx) => (
            <div
              key={idx}
              className={`w-full h-[240px] relative cursor-pointer mx-2 overflow-hidden`}
              onClick={() => navigate(`/products?category=${category.title}`)}
            >
              <div className="w-full h-full mx-4 rounded-md shadow transition-transform duration-300 transform hover:scale-110">
                <img
                  src={category.image_Url}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <div className="absolute bottom-4 mx-4 left-4 right-4 bg-white bg-opacity-85 p-2 flex justify-between items-center rounded-md transition-bg duration-300 hover:bg-[#f8aca3]">
                <h5 className={`text-[18px] leading-[1.3]`}>
                  {category.title}
                </h5>
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-[#f8aca3] text-white opacity-90 transition-colors duration-300 hover:bg-white hover:text-black">
                  <FaArrowRight size={16} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
    </div>
  );
};

export default Categories;
