import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createBlog } from "../../redux/actions/blog";
import { RxAvatar } from "react-icons/rx";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateBlogForm = () => {
  const [avatar, setAvatar] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const { isAdmin, user } = useSelector((state) => state?.user);
  const { isDermatologist, dermatologist } = useSelector(
    (state) => state?.dermatologist
  );
  const { isSeller, seller } = useSelector((state) => state?.seller);

  const { blogs, error, success, successMessage } = useSelector(
    (state) => state?.blog
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (success) {
      toast.success(successMessage);
      setTitle("");
      setContent("");
      setAvatar("");
      dispatch({ type: "clearMessages" });
    }
  }, [error, success, successMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log({
    // title,
    // content,
    // });

    const authorId = user?._id || dermatologist?._id || seller?._id;
    let authorModel;

    if (user?.role === "Admin") {
      authorModel = "User";
    } else if (isDermatologist) {
      authorModel = "Dermatologist";
    } else if (isSeller) {
      authorModel = "Vendor";
    } else {
      toast.error("You don't have permission to perform this role");
      return;
    }

    dispatch(createBlog(title, content, authorId, authorModel, avatar));
    // console.log("🚀 ~ file: CreateBlogForm.jsx:58 ~ handleSubmit ~ title, content, authorId, authorModel, avatar:", title, content, authorId, authorModel, avatar)
  };

  const handleFileInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const modules = {
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        { font: ["Arial", "Comic Sans MS", "Verdana", "Impact"] },
      ],
      [{ size: ["small", "normal", "large", "huge", "24px", "36px"] }],

      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <form className="grid gap-4 mb-4 sm:grid-cols-2" onSubmit={handleSubmit}>
      <div>
        <label
          htmlFor="name"
          className="block mb-2 text-lg font-medium text-gray-900"
        >
          Title
        </label>
        <input
          type="text"
          name="name"
          id="name"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 focus:ring-[#f84d17] placeholder-gray-400 focus:ring-2 focus:ring-offset-1"
          placeholder="Type product name"
          required
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      {/* Other input fields */}
      {/* Add more fields as needed */}
      <div className="sm:col-span-2">
        <label
          htmlFor="description"
          className="block mb-2 text-lg font-medium text-gray-900"
        >
          Content
        </label>
        <ReactQuill
          id="content"
          value={content}
          onChange={(value) => setContent(value)}
          modules={modules}
          formats={formats}
          className="bg-gray-50 rounded-lg border border-gray-300 focus:ring-[#f84d17] focus:ring-2 focus:ring-offset-1"
          placeholder="Write post description here"
          required
        />
      </div>

      <div className="mt-2 flex items-center">
        <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
          {avatar ? (
            <img
              src={avatar}
              alt="avatar"
              className="h-full w-full object-cover rounded-full"
            />
          ) : (
            <RxAvatar className="h-8 w-8" />
          )}
        </span>
        <label
          htmlFor="file-input"
          className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          <span>Upload Blog Image</span>
          <input
            type="file"
            name="avatar"
            id="file-input"
            onChange={handleFileInputChange}
            className="sr-only"
          />
        </label>
      </div>

      {/* Move the button below the image upload input */}

      <div className="sm:col-span-2">
        <button
          onClick={handleSubmit}
          className={`w-full mt-5 rounded-md border border-red-500 text-[#f84d17] px-5 py-2.5 text-center text-lg font-medium hover:bg-[#f84d17] hover:text-white`}
        >
          Create New Post
        </button>
      </div>
    </form>
  );
};

export default CreateBlogForm;
