import React  from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Typography,
  Collapse,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Card,
} from "@material-tailwind/react";

import {
  ChevronDownIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

import {
  RocketLaunchIcon,
} from "@heroicons/react/24/solid";

// const joinListItems = [

//   {
//     title: "",
//     description:
//       "Sign up as a Vendor and tap into a thriving community of Skincare Enthusiasts eager to discover Your products",
//     url: "/shop-create",
//   },
//   {
//     title: "JOIN AS A DERMATOLOGIST",
//     description:
//       "Register as a Dermatologist to share Your expertise, help Users navigate their Skincare journeys, and make a difference in their lives.",
//     url: "/dermatologist-signup",
//   },
// ];

export default function JoinListMenu() {
  const [isJoinListMenuOpen, setIsJoinListMenuOpen] = React.useState(false);
  const [isMobileJoinListMenuOpen, setIsMobileJoinListMenuOpen] =
    React.useState(false);

 const {isAuthenticated, user} = useSelector((state) => state?.user);
 const {isDermatologist} = useSelector((state) => state?.dermatologist); 
 const {isSeller} = useSelector((state) => state?.seller);  


  const renderItems = () => (
    <>
      {!isAuthenticated && (
        <Link to={"/sign-up"}>
          <MenuItem>
            <Typography variant="h6" color="blue-gray" className="mb-1 text-lg">
              JOIN AS A REGULAR USER
            </Typography>
            <Typography
              variant="small"
              color="gray"
              className="font-normal text-lg"
            >
              Join us today and unlock a world of personalized skincare
              recommendations and advice tailored to your unique needs
            </Typography>
          </MenuItem>
        </Link>
      )}

      {!isSeller && (
        <Link to={"/shop-create"}>
          <MenuItem>
            <Typography variant="h6" color="blue-gray" className="mb-1 text-lg">
              JOIN AS A BRAND/VENDOR
            </Typography>
            <Typography
              variant="small"
              color="gray"
              className="font-normal text-lg"
            >
              Sign up as a Vendor and tap into a thriving community of Skincare
              Enthusiasts eager to discover Your products
            </Typography>
          </MenuItem>
        </Link>
      )}
      {!isDermatologist && (
        <Link to={"/dermatologist-signup"}>
          <MenuItem>
            <Typography variant="h6" color="blue-gray" className="mb-1 text-lg">
              JOIN AS A DERMATOLOGIST
            </Typography>
            <Typography
              variant="small"
              color="gray"
              className="font-normal text-lg"
            >
              Register as a Dermatologist to share Your expertise, help Users
              navigate their Skincare journeys, and make a difference in their
              lives
            </Typography>
          </MenuItem>
        </Link>
      )}
    </>
  );






  return (
    <React.Fragment>
      <Menu
        allowHover
        open={isJoinListMenuOpen}
        handler={setIsJoinListMenuOpen}
      >
        <MenuHandler>
          <Typography variant="large" className="font-normal text-white">
            <MenuItem
              className="hidden items-center gap-2 font-medium text-blue-gray-900 lg:flex lg:rounded-full"
              selected={isJoinListMenuOpen || isMobileJoinListMenuOpen}
              onClick={() => setIsMobileJoinListMenuOpen((cur) => !cur)}
            >
              <UserGroupIcon className="h-5 w-5 inline-block" />
              
                Join Us
              
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${
                  isJoinListMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid">
          <Card
            // color="theme-button-bg"
            style={{ backgroundColor: "#f8aca3" }}
            shadow={false}
            variant="gradient"
            className="col-span-3 grid h-full w-full place-items-center rounded-md"
          >
            <RocketLaunchIcon
              strokeWidth={1}
              className="h-28 w-28"
              color="white"
            />
          </Card>
          <ul className="col-span-4 flex w-full flex-col gap-1">
            {renderItems()}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem
        className="flex items-center gap-2 font-medium  text-blue-gray-900 lg:hidden"
        onClick={() => setIsMobileJoinListMenuOpen((cur) => !cur)}
      >
        {" "}
        <UserGroupIcon className="h-5 w-5 inline-block" />
        Join Us{" "}
        <ChevronDownIcon
          strokeWidth={2}
          className={`h-3 w-3 transition-transform ${
            isMobileJoinListMenuOpen ? "rotate-180" : ""
          }`}
        />
      </MenuItem>
      {/* <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">
        {renderItems}
      </ul> */}
      <div className="block lg:hidden">
        <Collapse open={isMobileJoinListMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}



 
