const styles = {
  custom_container: "w-8/12 hidden sm:block",
  heading:
    "text-[27px] text-center md:text-start font-[600] font-Roboto pb-[20px]",

  section: "w-9/12 mx-auto",
  //header_section: "w-full h-[80px] 800px:pt-[20px] mx-auto bg-[#636363]",

  productTitle: "text-[25px] font-[600] font-Roboto text-[#333]",
  //productDiscountPrice: "font-bold text-[18px] text-[#333] font-Roboto",
  productDiscountPrice: " font-bold font-inter font-size-20px text-[#333]",
  price: "font-[500] text-[12px] text-[#666] pl-3 mt-[-4px] line-through",
  //shop_name: "pt-3 text-[15px] text pb-3",
  shop_name:
    "pb-3 font-[500] text-inter font-sans text-7px leading-7px text-start uppercase tracking-normal text-[#666]",
  active_indicator: "absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]",
  button:
    "w-[160px] bg-black h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer",
  cart_button:
    "px-[20px] h-[38px] rounded-[20px] bg-[#f63b60] flex items-center justify-center cursor-pointer",
  cart_button_text: "text-[#fff] text-[16px] font-[600]",
  input: "w-full border p-1 rounded-[5px]",
  activeStatus:
    "w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]",
  normalFlex: "flex items-center",
};

export default styles;
