import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CueTheNew = () => {
  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);
  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.sort(
      (a, b) => b.createdAt - a.createdAt
    );
    const firstSix = sortedData && sortedData.slice(0, 2);
    setData(firstSix);
  }, [allProducts]);

  return (
    <div className={`w-9/12 mx-auto mb-20 hidden lg:block`}>
      <section>
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-12 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:items-stretch">
            <div className="grid p-6 bg-gray-100 rounded place-content-center sm:p-8">
              <div className="max-w-md mx-auto text-center lg:text-left">
                <header>
                  <h2 className="text-4xl font-bold text-gray-900">
                    Cue The New
                  </h2>

                  <p className="mt-4 text-gray-500 text-xl">
                    Check out our latest new products and stay ahead of the
                    trend!
                  </p>
                </header>

                <Link
                  to="/products"
                  className="inline-block px-12 py-3 mt-8 text-sm font-medium text-white transition bg-gray-900 border border-gray-900 rounded hover:shadow focus:outline-none focus:ring"
                >
                  Shop All
                </Link>
              </div>
            </div>

            <div className="lg:col-span-2 lg:py-8">
              <ul className="grid grid-cols-2 gap-4">
                {data && data.length !== 0 && (
                  <>
                    {data.map((item) => (
                      <li key={item._id}>
                        <Link
                          to={`/product/${item._id}`}
                          className="block group"
                        >
                          <img
                            src={`${item?.images && item?.images[0]?.url}`}
                            alt=""
                            className="object-cover w-full rounded aspect-square h-[350px]"
                          />

                          <div className="mt-3">
                            <h3 className="font-medium text-gray-900 group-hover:underline group-hover:underline-offset-4">
                              {item?.name}
                            </h3>

                            <p className="mt-1 text-sm text-gray-700">
                              Ksh {item?.discountPrice}
                            </p>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CueTheNew;
