import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";

import Logo from "../../Assests/images/Logo.png";

const DermatologistDashboardHeader = () => {
  const { dermatologist } = useSelector((state) => state.dermatologist);
  return (
    <div className="w-full h-[80px] shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div>
        <Link to="/dermatologist-dashboard">
          <img
            // src="https://shopo.quomodothemes.website/assets/images/logo.svg"
            src={Logo}
            style={{ height: 150, width: 250 }}
            alt=""
          />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Link to="/dermatologist-dashboard-messages" className="800px:block hidden">
            <BiMessageSquareDetail
              color="#000"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link>
          <Link to={`/dermatologist-settings`}>
            <img
              src={`${dermatologist.avatar?.url}`}
              alt=""
              className="w-[50px] h-[50px] rounded-full object-cover"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DermatologistDashboardHeader;
