import React, {useState} from "react";
import { CgProfile } from "react-icons/cg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

export default function ProfileMenu() {
  const [isProfileMenu, setIsProfileMenu] = useState(false);


  const closeMenu = () => setIsProfileMenu(false);

  const { isAuthenticated, user } = useSelector((state) => state.user);
  //console.log("🚀 ~ file: ProfileMenu.jsx:56 ~ ProfileMenu ~ isAuthenticated:", isAuthenticated)
  const {isSeller, seller } = useSelector((state) => state.seller);
  //console.log("🚀 ~ file: ProfileMenu.jsx:57 ~ ProfileMenu ~ isSeller:", isSeller)
  const { isDermatologist, dermatologist } = useSelector((state) => state.dermatologist);
  //console.log("🚀 ~ file: ProfileMenu.jsx:58 ~ ProfileMenu ~ isDermatologist:", isDermatologist)

  return (
    <div className={`flex items-center`}>
      <div className="relative cursor-pointer mr-[15px]">
        {user && isAuthenticated && (
          <Link to="/profile">
            <img
              src={`${user?.avatar?.url}`}
              className="w-[35px] h-[35px] rounded-full"
              alt=""
            />
          </Link>
        )}
        {dermatologist && isDermatologist && (
          <Link to="/dermatologist-dashboard">
            <h1 className="text-[#fff] flex items-center bg-black rounded p-2">
              Go To Dashboard
              <IoIosArrowForward className="ml-1" />
            </h1>
          </Link>
        )}
        
        {seller && isSeller && (
          <Link to="/dashboard">
            <h1 className="text-[#fff] flex items-center bg-black rounded p-2">
              Go To Dashboard
              <IoIosArrowForward className="ml-1" />
            </h1>
          </Link>
        )}

        {!isAuthenticated && !isDermatologist && !isSeller && (
          <Link to="/login">
            <CgProfile size={30} color="rgb(255, 255, 255 / 83%) " />
          </Link>
        )}
      </div>
    </div>
  );
}
