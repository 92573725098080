import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.0 + ITEM_PADDING_TOP,

      width: 250,
    },
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#f84d17",
    },
  },
});

const specialties = [
  "Dermatological Skincare",
  "Anti-Aging Skincare",
  "Organic/Natural Skincare",
  "Cosmeceuticals",
  "Beauty and Makeup",
  "Sun Care Products",
  "Men's Skincare",
  "Ethnic Skincare",
  "Bath and Body Care",
  "Hair Care",
  "Fragrances",
  "Wellness Products",
  "Professional Salon Supplies",
  "Baby and Child Skincare",
];

export default function StoreSpecialtyFilter({ setSpecialties }) {
  const [selectedSpecialties, setSelectedSpecialties] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSpecialties(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setSpecialties(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <FormControl sx={{ m: 2, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            Filter By Specialty
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            sx={{ height: 45 }}
            value={selectedSpecialties}
            onChange={handleChange}
            input={<OutlinedInput label="Specialty" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {specialties.map((specialty) => (
              <MenuItem key={specialty} value={specialty}>
                <Checkbox
                  checked={selectedSpecialties.indexOf(specialty) > -1}
                />
                <ListItemText primary={specialty} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
      {/* Render the filtered stores here */}
    </div>
  );
}
