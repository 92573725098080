import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {

  Collapse,
  Typography,
 
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,

} from "@material-tailwind/react";

import {
  ChevronDownIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";

import {
  Bars4Icon,
  GlobeAmericasIcon,
  NewspaperIcon,
  PhoneIcon,
  RectangleGroupIcon,
  SquaresPlusIcon,
  SunIcon,
  TagIcon,
  UserGroupIcon,
  GlobeAltIcon,
  BookOpenIcon,
} from "@heroicons/react/24/solid";

import JoinListMenu from "./JoinListMenu";

const navListMenuItems = [
  {
    title: "Shop Now",
    description: "Find the perfect solution for your Skin Needs.",
    icon: SquaresPlusIcon,
    url: "/products",
  },
  {
    title: "Explore Vendors",
    description: " Discover the Best Brands and Products for Your Skin.",
    icon: UserGroupIcon,
    url: "/stores",
  },
  {
    title: " Quizzical Glow",
    description: "Take Our Skin Quiz to Get Personalized Recommendations.",
    icon: Bars4Icon,
    url: "/skin-quiz",
  },
  {
    title: "Connect With a Dermatologist",
    description: "Book a Consultation with a Dermatologist Today.",
    icon: RectangleGroupIcon,
    url: "/dermatologist",
  },

  {
    title: "About Us",
    description: "Learn more about our mission and values.",
    icon: NewspaperIcon,
    url: "/about-us",
  },

  {
    title: "Services",
    description: "Learn how we can help you achieve your goals.",
    icon: SunIcon,
    url: "/services",
  },
  {
    title: "Our Blog",
    description: "Read our latest blog posts.",
    icon: BookOpenIcon,
    url: "/blog",
  },

  {
    title: "FAQ",
    description: "Frequently Asked Questions.",
    icon: GlobeAmericasIcon,
    url: "/faq",
  },
  {
    title: "Privacy Policy",
    description: "Learn more about our privacy policy.",
    icon: TagIcon,
    url: "/privacy-policy",
  },
  {
    title: "Contact Us",
    description: "Get in touch to find the perfect solution for your needs.",
    icon: PhoneIcon,
    url: "/contact-us",
  },
];

function NavListMenu() {
  const [isNavListMenuOpen, setIsNavListMenuOpen] = React.useState(false);
  const [isMobileNavListMenuOpen, setIsMobileNavListMenuOpen] =
    React.useState(false);


  const renderItems = navListMenuItems.map(
    ({ icon, title, description, url }, key) => (
      <Link to={url} key={key}>
        <MenuItem className="flex items-center gap-3 rounded-lg">
          <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
            {" "}
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-gray-900 w-6",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color="blue-gray"
              className="flex items-center text-lg font-bold"
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-sm !font-medium text-blue-gray-500"
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </Link>
    )
  );

  return (
    <React.Fragment>
      <Menu
        open={isNavListMenuOpen}
        handler={setIsNavListMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="large" className="font-medium">
            <MenuItem
              className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
              selected={isNavListMenuOpen || isMobileNavListMenuOpen}
              onClick={() => setIsMobileNavListMenuOpen((cur) => !cur)}
            >
              <GlobeAltIcon className="h-[18px] w-[18px] text-blue-gray-500" />{" "}
              Explore
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isNavListMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileNavListMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden mw-full rounded-xl lg:block">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileNavListMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

export default function NavList() {
    const navigate = useNavigate();


  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Typography variant="large" color="blue-gray" className="font-medium">
        <ListItem
          className="flex items-center gap-2 py-2 pr-4"
          onClick={() => navigate("/")}
        >
          {" "}
          <HomeIcon className="h-5 w-5" /> Home
        </ListItem>
      </Typography>
      <NavListMenu />
      <JoinListMenu />
    </List>
  );
}
