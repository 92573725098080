import axios from "axios";
import { server } from "../../server";

// load user
export const loadDermatologist = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadDermatologistRequest",
    });
    const { data } = await axios.get(`${server}/dermatologist/getDermatologist`, {
      withCredentials: true,
  
    });
    dispatch({
      type: "LoadDermatologistSuccess",
      payload: data.dermatologist,
    });
  } catch (error) {
    dispatch({
      type: "LoadDermatologistFail",
      payload: error?.response?.data?.message,
    });
  }
};

// load seller
export const loadSeller = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadDermatologistRequest",
    });
    const { data } = await axios.get(`${server}/dermatologist/getDermatologist`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadDermatologistSuccess",
      payload: data.seller,
    });
  } catch (error) {
    dispatch({
      type: "LoadDermatologistFail",
      payload: error?.response?.data?.message,
    });
  }
};

// dermatologist update information
export const updateDermatologistInformation =
  (name, email, phoneNumber, description, password) => async (dispatch) => {
    try {
      dispatch({
        type: "updateDermatologistInfoRequest",
      });

      const { data } = await axios.put(
        `${server}/dermatologist/update-dermatologist-info`,
        {
          email,
          password,
          phoneNumber,
          description,
          name,
        },
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Credentials": true,
          },
        }
      );

      dispatch({
        type: "updateDermatologistInfoSuccess",
        payload: data.dermatologist,
      });
    } catch (error) {
      dispatch({
        type: "updateDermatologistInfoFailed",
        payload: error?.response?.data?.message,
      });
    }
  };

// update dermatologist address
export const updateDermatologistAddress =
  (country, city, address1, address2, zipCode, addressType) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "updateDermatologistAddressRequest",
      });

      const { data } = await axios.put(
        `${server}/dermatologist/update-dermatologist-addresses`,
        {
          country,
          city,
          address1,
          address2,
          zipCode,
          addressType,
        },
        { withCredentials: true }
      );

      dispatch({
        type: "updateDermatologistAddressSuccess",
        payload: {
          successMessage: "Dermatologist address updated succesfully!",
          dermatologist: data.dermatologist,
        },
      });
    } catch (error) {
      dispatch({
        type: "updateDermatologistAddressFailed",
        payload: error?.response?.data?.message,
      });
    }
  };


// delete dermatologist address
export const deleteDermatologistAddress = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteDermatologistAddressRequest",
    });

    const { data } = await axios.delete(
      `${server}/dermatologist/delete-dermatologist-address/${id}`,
      { withCredentials: true }
    );

    dispatch({
      type: "deleteDermatologistAddressSuccess",
      payload: {
        successMessage: "Dermatologist deleted successfully!",
        dermatologist: data.dermatologist,
      },
    });
  } catch (error) {
    dispatch({
      type: "deleteDermatologistAddressFailed",
      payload: error?.response?.data?.message,
    });
  }
};

// get all dermatologists --- admin
export const getAllDermatologists = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllDermatologistsRequest",
    });

    const { data } = await axios.get(`${server}/dermatologist/admin-all-dermatologists`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllDermatologistsSuccess",
      payload: data.dermatologists,
    });
  } catch (error) {
    dispatch({
      type: "getAllDermatologistsFailed",
      payload: error?.response?.data?.message,
    });
  }
};
