import { useEffect, useState } from "react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import { categoriesData } from "../../../../static/data";

export default function CategoryFilter({selectedCategory, setSelectedCategory }) {
  const [selectedProductCategory, setSelectedProductCategory] = useState(selectedCategory);
  const [isCollapsed, setIsCollapsed] = useState(true);


useEffect(() => {
  setSelectedProductCategory(selectedCategory);
}, [selectedCategory]);

  const handleCategoryClick = (categoryTitle) => {
    setSelectedProductCategory(categoryTitle);
    setSelectedCategory(categoryTitle);
  };

  const handleMenuIconClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Card>
      <div className="flex justify-between items-center">
        <Typography
          color="black"
          className="font-medium text-black-500 px-3 py-2 text-lg"
        >
          Category
        </Typography>
        <button
          className="block md:hidden px-3 py-2 text-red-500 hover:text-red-600 focus:outline-none"
          onClick={handleMenuIconClick}
        >
          <svg
            className="h-6 w-6 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isCollapsed ? (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm16 4H4v2h16v-2z"
              />
            ) : (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z"
              />
            )}
          </svg>
        </button>
      </div>
      <List
        className={`${
          isCollapsed ? "hidden md:block" : "block"
        } md:block overflow-y-auto`}
      >
        <ListItem key={"All"} className="p-0">
          <label
            htmlFor={`category-all`}
            className="flex w-full cursor-pointer items-center px-3 py-2"
          >
            <ListItemPrefix className="mr-3">
              <Radio
                ripple={true}
                color="red"
                name="category"
                id={`category-all`}
                value={"All"}
                className="hover:before:opacity-0"
                containerProps={{
                  className: "p-0",
                }}
                checked={selectedProductCategory === "All"}
                onChange={() => handleCategoryClick("All")}
              />
            </ListItemPrefix>
            <Typography
              color="blue-gray"
              className="font-medium text-blue-gray-400"
            >
              All
            </Typography>
          </label>
        </ListItem>
        {categoriesData.map((category) => (
          <ListItem key={category.id} className="p-0">
            <label
              htmlFor={`category-${category.id}`}
              className="flex w-full cursor-pointer items-center px-3 py-2"
            >
              <ListItemPrefix className="mr-3">
                <Radio
                  ripple={true}
                  color="red"
                  name="category"
                  id={`category-${category.id}`}
                  value={category.title}
                  className="hover:before:opacity-0"
                  containerProps={{
                    className: "p-0",
                  }}
                  checked={selectedProductCategory === category.title}
                  onChange={() => handleCategoryClick(category.title)}
                />
              </ListItemPrefix>
              <Typography
                color="blue-gray"
                className="font-medium text-blue-gray-400"
              >
                {category.title}
              </Typography>
            </label>
          </ListItem>
        ))}
      </List>
      <style jsx>{`
        @media (max-width: 640px) {
          .flex {
            flex-direction: column;
          }
          .cursor-pointer {
            cursor: pointer;
          }
          .items-center {
            align-items: center;
          }
          .px-3 {
            padding-left: 1rem;
            padding-right: 1rem;
          }
          .py-2 {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }
          .mr-3 {
            margin-right: 0;
            margin-bottom: 0.5rem;
          }
          .font-medium {
            font-weight: 500;
          }
        }
      `}</style>
    </Card>
  );
}
