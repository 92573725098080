import React from "react";
// import { AiOutlineGift } from "react-icons/ai";
// import { BiMessageSquareDetail } from "react-icons/bi";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../Assests/images/Logo.png";

const AdminHeader = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <div className="w-full h-[80px] shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div>
        <Link to="/">
          <img
            // src="https://shopo.quomodothemes.website/assets/images/logo.svg"
            src={Logo}
            style={{ height: 150, width: 250 }}
            alt=""
          />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Link to="/admin-events" className="800px:block hidden">
            <MdOutlineLocalOffer
              //i want grey color code
              color=" #000"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link>
          <Link to="/admin-products" className="800px:block hidden">
            <FiShoppingBag
              color="#000"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link>
          <Link to="/admin-orders" className="800px:block hidden">
            <FiPackage color="#000" size={30} className="mx-5 cursor-pointer" />
          </Link>
          {/* <Link to="/admin-messages" className="800px:block hidden">
            <BiMessageSquareDetail
              color="#fff"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link> */}
          <img
            src={`${user?.avatar?.url}`}
            alt=""
            className="w-[50px] h-[50px] rounded-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
