import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "survey-core/defaultV2.min.css";
import "../quiz.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { quizJson } from "./quiz";
import { surveyThemeJson } from "./surveytheme";
import ProductCard from "../../Route/ProductCard/ProductCard";
import Loader from "../../Layout/Loader";
import { AiOutlineArrowRight } from "react-icons/ai";
import { toast } from "react-toastify";


import { updateUserSkinType } from "../../../redux/actions/user";

function animate(isShowing) {
  const element = document.getElementById("surveyElement");
  if (!!element) {
    const classes = element.classList;
    if (!classes.contains("hiddenSurveyElement")) {
      classes.add("hiddenSurveyElement");
    }
    isShowing
      ? classes.add("visibleSurveyElement")
      : classes.remove("visibleSurveyElement");
  }
};

function showAnimated (){
  animate(true);
};
function hideAnimated () {
  animate(false);
};
let doAnimation = true;

const SkinQuizComponent = () => {
  const survey = new Model(quizJson);

    const [showSurvey, setShowSurvey] = useState(true);
    const [skinType, setSkinType] = useState("");
    const { allProducts, isLoading } = useSelector(
         (state) => state?.products
    );

    const { loading, isAuthenticated } = useSelector((state) => state?.user);
    const { user, error, successMessage } = useSelector((state) => state.user);
   // console.log("🚀 ~ file: SkinQuizComponent.jsx:46 ~ SkinQuizComponent ~ user:", user)


    const [filteredProducts, setFilteredProducts] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

useEffect(() => {
 if (!showSurvey) {

  const skinProducts = allProducts.filter((i) => i.skinType === skinType);
      setFilteredProducts(skinProducts);
 }
}, [allProducts, skinType, showSurvey]);

useEffect(() => {
  if (isAuthenticated && user) {
    setSkinType(user?.skinType);
    setShowSurvey(false);
  }
}, [isAuthenticated, user])


useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
}, [error, successMessage]);

  const handleUpdateSkinType = (skinType) => {
    dispatch(updateUserSkinType(skinType));
  };


  survey.applyTheme(surveyThemeJson);
  survey.showProgressBar = "bottom";

 // survey.onAfterRenderSurvey.add(showAnimated);
  survey.onCurrentPageChanging.add(function (_, options) {
    if (!doAnimation) return;
    options.allow = false;
    setTimeout(function () {
      doAnimation = false;
      survey.currentPage = options.newCurrentPage;
      doAnimation = true;
    }, 300);
    hideAnimated();
  });

  survey.onCurrentPageChanged.add(showAnimated);
  survey.onCompleting.add(function (_, options) {
    if (!doAnimation) return;
    options.allow = false;
    setTimeout(function () {
      doAnimation = false;
      survey.doComplete();
      doAnimation = true;
    }, 300);
    hideAnimated();
  });


function calculateSkinType(survey) {
  // Calculate total score based on user's responses
  let totalScore = 0;
  let totalQuestions = 0;

  const pages = survey.pages;

  pages.forEach((page) => {
    const questions = page.questions;
    questions.forEach((question) => {
      if (question.getType() === "rating") {
        totalScore += parseInt(question.value, 10);
        totalQuestions++;
      }
    });
  });

  // Calculate average score
  const averageScore = totalScore / totalQuestions;
 // console.log("🚀 ~ file: SkinQuizComponent.jsx:83 ~ calculateSkinType ~ averageScore:", averageScore)
  

  let skinType = "";

  // Determine skin type based on average score
  if (averageScore >= 4.5) {
    skinType = "Oily";
  } else if (averageScore >= 3.5) {
    skinType = "Combination";
  } else if (averageScore >= 2.5) {
    skinType = "Normal";
  } else if (averageScore >= 1.5) {
    skinType = "Dry";
  } else {
    skinType = "Sensitive";
  }

  return skinType;
}


survey.onComplete.add(function (sender) {
    const skinTypeValue = calculateSkinType(sender);
    setShowSurvey(false);
    setSkinType(skinTypeValue);
    handleUpdateSkinType(skinTypeValue);
});

const retakeSurvey = () => {
    survey.clear(); 
    setShowSurvey(true); 
}; 

return (
  <div>
    <div className="survey-container mb-5">
      <div className="survey-content">
        {showSurvey && <Survey id="surveyElement" model={survey} />}
        {!showSurvey && (
          <div className="flex flex-col items-center">
            <h3 className="mt-5 text-center font-light md:text-4xl">
              Your Skin Type is
              <span className="font-semibold italic"> {skinType}</span>
            </h3>
            <button
              onClick={retakeSurvey}
              className="bg-theme-button-bg my-10 text-white font-semibold py-3 px-6 rounded-full hover:bg-black hover:text-white "
            >
              Retake The Quiz
            </button>

            {!isAuthenticated && (
              <div className="flex flex-col items-center">
                {" "}
                <h2 className="mt-5 text-center font-semibold md:text-4xl">
                  Get Personalized Skin Care Product Recommendations <br />
                  Everytime You Use Our Platform
                </h2>
                <button
                  onClick={() => navigate("/login")} // Replace '/signup' with your actual sign up page URL
                  className="bg-theme-button-bg my-10 text-xl  text-white font-semibold py-4 px-8 rounded-full hover:bg-black hover:text-white"
                >
                  Start Your Journey
                  <AiOutlineArrowRight className="inline ml-2" />
                </button>{" "}
              </div>
            )}
          </div>
        )}

        {!showSurvey && isLoading && <Loader />}
        {!showSurvey && !isLoading && (
          <div className={`w-full mx-auto flex flex-col items-center `}>
            {
              <div className={`text-4xl font-light mb-5 md:text-start `}>
                <h4>
                  {" "}
                  Based On Your Results, We Recommend The Following Products
                </h4>
              </div>
            }
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {filteredProducts &&
                filteredProducts.length >= 0 &&
                filteredProducts.map((i, index) => (
                  <ProductCard data={i} key={index} />
                ))}
            </div>
            {filteredProducts && filteredProducts.length === 0 ? (
              <h1 className="text-center my-5 font-semibold text-2xl">
                There are Currently No Products Available for Your Skin Type!
              </h1>
            ) : null}
          </div>
        )}
      </div>
    </div>
  </div>
);
};

export default SkinQuizComponent;