import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { server } from "../../server";
import styles from "../../styles/styles";
import Loader from "../Layout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLeft } from "react-icons/ai";
import { toast } from "react-toastify";

const DermatologistInfo = ({ isOwner }) => {
  const [data,setData] = useState({});
  const [isLoading,setIsLoading] = useState(false);
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/dermatologist/get-dermatologist-info/${id}`).then((res) => {
     setData(res.data.dermatologist);
     setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
    })
  }, [])
  

  const logoutHandler = async () => {
    // axios.get(`${server}/dermatologist/logout`,{
    //   withCredentials: true,
    // });
    // window.location.reload();
    axios
      .get(`${server}/dermatologist/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/dermatologist-login");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });


  };


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => navigate(-1)}
          >
            <AiOutlineLeft size={30} color="crimson" />
            <h1
              className="pl-2 text-[25px] cursor-pointer"
              style={{ color: "crimson" }}
            >
              Back
            </h1>
          </div>
          <div className="w-full py-5">
            <div className="w-full flex item-center justify-center">
              <img
                src={`${data.avatar?.url}`}
                alt=""
                className="w-[150px] h-[150px] object-cover rounded-full"
              />
            </div>
            <h3 className="text-center py-2 text-[20px]">{data.name}</h3>
            <p className="text-[16px] text-[#000000a6] p-[10px] flex items-center">
              {data.description}
            </p>
          </div>
          <div className="p-3">
            <h5 className="font-[600]">Address</h5>
            <h4 className="text-[#000000a6]">{data.address}</h4>
          </div>
          <div className="p-3">
            <h5 className="font-[600]">Phone Number</h5>
            <h4 className="text-[#000000a6]">{data.phoneNumber}</h4>
          </div>

          {/* <div className="p-3">
            <h5 className="font-[600]">Dermatologist Ratings</h5>
            <h4 className="text-[#000000b0]">{averageRating}/5</h4>
          </div> */}
          <div className="p-3">
            <h5 className="font-[600]">Joined On</h5>
            <h4 className="text-[#000000b0]">
              {data?.createdAt?.slice(0, 10)}
            </h4>
          </div>
          {isOwner && (
            <div className="py-3 px-4">
              <Link to="/settings">
                <div
                  className={`${styles.button} !w-full !h-[42px] !rounded-[5px]`}
                >
                  <span className="text-white">Edit Settings</span>
                </div>
              </Link>
              <div
                className={`${styles.button} !w-full !h-[42px] !rounded-[5px]`}
                onClick={logoutHandler}
              >
                <span className="text-white">Log Out</span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DermatologistInfo;