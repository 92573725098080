import React from "react";
import { useSelector } from "react-redux";
import EventCard from "../components/Events/EventCard";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";

//import Announcements from "../components/Layout/Announcements";
import Footer from "../components/Layout/Footer";
import StoreListingHero from "../components/utils/StoreListingHero";

const EventsPage = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);
  console.log(
    "🚀 ~ file: EventsPage.jsx:11 ~ EventsPage ~ allEvents:",
    allEvents
  );

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && allEvents?.length === 0 && (
        <div>
          {" "}
          {/* <Announcements /> */}
          <Header activeHeading={5} />{" "}
          <StoreListingHero
            title="Running Events"
            imageUrl="https://images.unsplash.com/photo-1484860348026-73c1044c9148?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODd8fGFmcmljYW4lMjB3b21hbnxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60"
          />{" "}
          <div>
            {" "}
            <h5 className="text-center my-10 font-semibold text-lg">
              No Running Events Currently!
            </h5>{" "}
          </div>
          <Footer />
        </div>
      )}
      {!isLoading && allEvents?.length !== 0 && (
        <div>
          {" "}
          {/* <Announcements /> */}
          <Header activeHeading={5} />
          <EventCard active={true} data={allEvents && allEvents[0]} />{" "}
          <Footer />
        </div>
      )}
    </>
  );
};

export default EventsPage;
