import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getAllBlogs } from "../../redux/actions/blog";
import { getBlog } from "../../redux/actions/blog";


import { AiOutlineLeft } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import BlogPlaceholder from "../../Assests/images/BlogPlaceholder.avif";
import UpdateBlogForm from "./UpdateBlogForm";

const BlogPostDetails = () => {


const navigate = useNavigate();
const [showUpdateModal, setShowUpdateModal] = useState(false);
const [blogPost, setBlogPost] = useState(null);

const { id } = useParams();
const { user } = useSelector((state) => state?.user);

const { isDermatologist, dermatologist } = useSelector(
(state) => state?.dermatologist
);

const dispatch = useDispatch();
const {  seller } = useSelector((state) => state?.seller);
const {blogs } = useSelector((state) => state?.blog);
const {blog} = useSelector((state) => state?.blog);

const toggleUpdateModal = () => {
setShowUpdateModal(!showUpdateModal);
};

const updateDetails = () => {
  dispatch(getBlog(id));
  setBlogPost(blog);
}
  useEffect(() => {
    dispatch(getAllBlogs());
    const newPost = blogs && blogs.find((i) => i._id === id);
    setBlogPost(newPost);
  }, [id, blogs]);

  return (
    <>
      <div className="max-w-screen-lg mx-auto">
        <div
          className=" flex items-center hover:text-red-500 m-3 "
          onClick={() => navigate(-1)}
        >
          <AiOutlineLeft className="font-bold" size={15} />
          <h1 className="pl-2 text-[18px] px-2 lg:px-0 font-bold cursor-pointer">
            Back
          </h1>
        </div>

        <main className="mt-10">
          <div className="mb-4 md:mb-0 w-full mx-auto relative">
            <div className="px-4 lg:px-0">
              <h2 className="text-4xl font-semibold text-gray-800 leading-tight">
                {blogPost?.title}
              </h2>
              <a
                href="#"
                className="py-2 text-[#f8aca3] inline-flex items-center justify-center mb-2"
              >
                {blogPost?.createdAt.substring(0, 10)}
              </a>
            </div>

            <img
              src={blogPost?.avatar ? blogPost?.avatar : BlogPlaceholder}
              className="w-full object-cover lg:rounded"
              style={{ height: "28em" }}
              alt="Blog Header"
            />
          </div>

          <div className="flex flex-col lg:flex-row lg:space-x-12">
            <div className="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
              <div dangerouslySetInnerHTML={{ __html: blogPost?.content }} />
            </div>

            <div className="w-full lg:w-1/4 m-auto mt-12 max-w-screen-sm">
              <div className="p-4 border-t border-b md:border md:rounded">
                <div className="flex py-2">
                  <img
                    src={blogPost?.author?.avatar?.url}
                    className="h-14 w-14 rounded-full mr-2 object-cover"
                  />
                  <div>
                    <p className="font-semibold text-gray-700 text-sm">
                      {" "}
                      {blogPost?.author?.name}{" "}
                    </p>
                    <p className="font-semibold text-gray-600 text-xs">
                      {" "}
                      {blogPost?.author?.role}{" "}
                    </p>
                  </div>
                </div>
                <p className="text-gray-700 py-3">
                  {blogPost?.author?.description}
                </p>
              </div>
            </div>
          </div>

          {user?.role === "Admin" ? (
            <div className="my-5">
              <button
                onClick={toggleUpdateModal}
                className={`w-50 flex mt-5 items-center justify-center rounded-md border 
                                         border-red-500 text-[#f84d17] px-5 py-2.5 text-center text-lg font-medium hover:bg-[#f84d17] hover:text-white`}
              >
                Update Blog Post
              </button>
            </div>
          ) : null}
        </main>
      </div>
      {showUpdateModal && (
        <div
          id="defaultModal"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-gray-500 bg-opacity-50"
          //onClick={toggleUpdateModal}
        >
          <div
            className="relative p-4 w-full max-w-2xl bg-white rounded-lg shadow sm:p-5"
            //onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5">
              <h3 className="text-2xl font-semibold text-gray-900">
                Update Blog Post
              </h3>
              <button
                type="button"
                onClick={toggleUpdateModal}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-2xl p-1.5 ml-auto inline-flex items-center"
              >
                <IoMdClose />
              </button>
            </div>
            <UpdateBlogForm
              postDetails={blogPost}
              updateDetails={updateDetails}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BlogPostDetails;