import React from "react";
import DermatologistDashboardHeader from "../../components/Dermatologist/DermatologistDashboardHeader";
import DermatologistDashboardSideBar from "../../components/Dermatologist/DermatologistDashboardSideBar";
import DermatologistDashboardHero from "../../components/Dermatologist/DermatologistDashboardHero";

const DermatologistDashboardPage = () => {
  return (
        <div>
          <DermatologistDashboardHeader />
          <div className="flex items-start justify-between w-full">
            <div className="w-[80px] 800px:w-[330px]">
              <DermatologistDashboardSideBar active={1} />
            </div>
            <DermatologistDashboardHero />
          </div>
        </div>
  );
};

export default DermatologistDashboardPage;
