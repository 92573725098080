import React from "react";
import { AiOutlineLogout, AiOutlineGift } from "react-icons/ai";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { VscNewFile } from "react-icons/vsc";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { server } from "../../server";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify"



const DermatologistDashboardSideBar = ({ active }) => {

 const navigate = useNavigate(); 

const logoutHandler = async () => {
  // axios.get(`${server}/shop/logout`,{
  //   withCredentials: true,
  // });
  // window.location.reload();
  axios
    .get(`${server}/dermatologist/logout`, { withCredentials: true })
    .then((res) => {
      toast.success(res.data.message);
      window.location.reload(true);
      navigate("/shop-login");
    })
    .catch((error) => {
      console.log(error.response.data.message);
    });
};



  return (
    <div className="w-full h-[90vh] bg-white shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link
          to="/dermatologist-dashboard"
          className="w-full flex items-center"
        >
          <RxDashboard
            size={30}
            color={`${active === 1 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/dermatologist-dashboard-messages"
          className="w-full flex items-center"
        >
          <BiMessageSquareDetail
            size={30}
            color={`${active === 8 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 8 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            Inbox
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/dermatologist-settings" className="w-full flex items-center">
          <CiSettings
            size={30}
            color={`${active === 11 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 11 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            Settings
          </h5>
        </Link>
      </div>

      <div className={"w-full flex items-center p-4"} onClick={logoutHandler}>
        <AiOutlineLogout
          size={30}
          color={`${active === 11 ? "crimson" : "#555"}`}
        />
        <h5
          className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
            active === 12 ? "text-[crimson]" : "text-[#555]"
          }`}
        ></h5>
        <h5
          className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
            active === 12 ? "text-[crimson]" : "text-[#555]"
          }`}
        >
          Log Out
        </h5>
      </div>
    </div>
  );
};

export default DermatologistDashboardSideBar;
