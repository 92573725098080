import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Login from "../components/Login/Login.jsx";

const LoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { isDermatologist } = useSelector((state) => state.dermatologist);
  //console.log("🚀 ~ file: Login.jsx:11 ~ LoginPage ~ isDermatologist:", isDermatologist)


  useEffect(() => {
    if(isAuthenticated === true || isSeller === true || isDermatologist === true){
      navigate("/");
    }
  }, [])
  
  return (
    <div>
        <Login />
    </div>
  )
}

export default LoginPage;