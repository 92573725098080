import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


export default function FeaturedProducts() {
    const { allProducts } = useSelector((state) => state.products);
    //console.log("🚀 ~ file: FeaturedProduct.jsx:86 ~ FeaturedProducts ~ allProducts:", allProducts)
    const [featuredProducts, setFeaturedProducts] = useState([])

    useEffect(() => {
      const allProductsData = allProducts ? [...allProducts] : [];
      const sortedData = allProductsData?.sort(
        (a, b) => b.createdAt - a.createdAt
      );
      const firstSix = sortedData && sortedData.slice(0, 6);
      setFeaturedProducts(firstSix);

    }, [allProducts]);

  return (
    <div className="bg-gray-100">
      <div className="mx-auto w-9/12 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
          <h2 className="text-4xl font-bold text-gray-900">Featured Products</h2>

          <div className=" space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {featuredProducts.map((product) => (
              <div key={product?.name} className="group relative">
                <div className="relative mt-5 h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                  <img
                    src={product?.images[0].url}
                    alt={product?.name}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="mt-6 text-sm text-gray-500 flex justify-between">
                  <Link to={`/product/${product?._id}`} href={product?.href}>
                    <span className="absolute inset-0  group-hover:underline group-hover:underline-offset-4" />
                    {product?.name}
                  </Link>
                  <Link to={`/product/${product?._id}`} href={product?.href}>
                    <span className="absolute inset-0" />
                    Ksh {product?.discountPrice}
                  </Link>
                </div>
                <p className="text-base font-semibold text-gray-900">
                  {product?.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
