import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";

import BlogPostDetails from "../../components/blog/BlogPostDetails";
import { useSelector } from "react-redux";

const BlogPostDetailsPage = () => {
const {blogs} = useSelector((state) => state?.blog);
  const { id } = useParams();
  const [post, setPost] = useState(null);


  useEffect(() => {
      const post = blogs && blogs.find((i) => i._id === id);
      setPost(post);
  }, [blogs, id]);

  return (
    <div>
      <Header />
      <BlogPostDetails post={post} />
      <Footer />
    </div>
  );
};

export default BlogPostDetailsPage;
